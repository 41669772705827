import {ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MapsAPILoader } from '@agm/core';

import { CheckoutApiService } from '../../Checkout-new/checkout-api.service';
import { CustomerLoginService } from '../../Checkout-new/checkout-login/customer-login.service';
import { CustomerLoginStorageService } from '../../Checkout-new/checkout-login/customer-login-storage.service';
import { ENDPOINTS,ORG_DETAILS } from 'src/app/app.config';
import { AlertsService } from 'src/app/Components/alerts/alerts.service';
import { LocalStorageService } from 'src/app/Services/auth-service/local-storage.service';
import { AppService } from 'src/app/app.service';
import { NavbarService } from 'src/app/Components/navbar/navbar.service';
import { RegistrationOfferApiService } from './registration-offer-api.service';
import { RegistrationOfferOrderService } from './registration-offer-order.service';
import { RegistrationOfferInitiationApiService } from './registration-offer-initiation-api.service';
import { config } from 'process';
import { config as configs } from "src/configs/config";
import { CartService } from "src/app/Lazy-loaded-Modules/Cart-module/cart-page/cart-page.service";
import { LoginService } from "./login-new.service";

const numPattern = /^[0-9]*$/;
const emailPattern = /^[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+$/i
const namePattern = /^[a-zA-Z\s.]*$/;

@Component({
  selector: 'app-login-new',
  templateUrl: './login-new.component.html'
})
export class LoginNewComponent implements OnInit {

  BUSINESS_CUSTOMER:string = "BUSINESS CUSTOMER";
  INDIVIDUAL_CUSTOMER:string = "INDIVIDUAL CUSTOMER";
  checkoutAlerts:boolean = false;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  backToUrl = "/";

  screenType = "initial";
  hideMobile = true;
  userIdType = "";
  loggedinAs = "";
  emailormobile = "";
  showLoginpaswd:boolean = false;
  showregistrationPswd:boolean = false;
  showForgotPswd:boolean = false;
  showotpwithoutpswd:boolean = false;
  showUpdateEmailForm1:boolean = false;
  showUpdateEmailForm3:boolean = false;
  customerType:string = "";
  enableBusinessUser:boolean = false;
  emailExist:boolean = false;
  passwordExists:boolean = false;
  resultMessage: any;
  customerType1:any;
  otpType:any;
  selectType:boolean = false;;
  config=configs;
  formData = [];
  forgotMobile:any;
  is_mandatory = {
    individual:null,
    business:null
  }
  otpResendDuration:Number
  optionss = {
    types: [],
    componentRestrictions: { country: 'IN' },
  };
  cleMobileNumber:any;
  cleEmail:any;
  id;

  validateInitialForm:boolean = false;
  validatePasswordForm:boolean = false;
  validateInitialRegistration:boolean = false;
  validateIndividualRegistration:boolean = false;
  validateBusinessRegistration:boolean = false;
  validateForgotPasswordForm:boolean = false;
  validateOtpLoginWithoutPswd:boolean = false;
  validaterequestOTPLoginForm:boolean = false;
  validateUpdateEmailForm1:boolean = false;
  validateUpdateEmailForm2:boolean = false;
  validateUpdateEmailForm3:boolean = false;

  initialForm: UntypedFormGroup;
  passwordForm: UntypedFormGroup;
  initialRegistration: UntypedFormGroup;
  individualRegistration: UntypedFormGroup;
  businessRegistration: UntypedFormGroup;
  forgotPasswordForm: UntypedFormGroup;
  otpLoginWithoutPswd: UntypedFormGroup;
  requestOTPLoginForm: UntypedFormGroup;
  updateEmailForm1: UntypedFormGroup;
  updateEmailForm2: UntypedFormGroup;
  updateEmailForm3: UntypedFormGroup;

  // REGISTRATION OFFER DETAILS
  registrationOfferDetails = {
    available:false,
    inidividual:false,
    business:false,
    product_ids:[]
  }

  validateShippingForm:boolean = false;
  location_bloked:boolean = false;
  is_deliverable:boolean = true;
  pickupLatitude:any;
  pickupLongitude:any;
  offerAvailed = false;
  shippingForm: UntypedFormGroup;
  latitude;
  longitude;
  zoom;
  geocoder;
  enabledistanceValidation = false;
  distance = 0;
  validAddress:any = "";
  productId:any = "";
  type:any = "";
  selectedAddress:any = null;

   // BUTTON SPINNERS
   continue1:boolean = false;
   continue2:boolean = false;
   signup1:boolean = false;
   signup2:boolean = false;
   resendMsg:string;
   saveaddSpinner:boolean = false;
   IECNo:boolean = false;
   ValidIECNo:boolean = false;
   IncorrectIECNo:boolean = false;

   // FOR OFFER
   individualCustomerRegistration:boolean = false;
   businessCustomerRegistration:boolean = false;
   offerOrderId:any = "";

  constructor(
    private formBuilder: UntypedFormBuilder,
    private checkoutApiService: CheckoutApiService,
    private customerLoginService: CustomerLoginService,
    private customerLoginStorageService: CustomerLoginStorageService,
    private alertService: AlertsService,
    private appService: AppService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private navbarService: NavbarService,
    private registrationOfferApiService: RegistrationOfferApiService,
    private mapsAPILoader: MapsAPILoader,
    private registrationOfferOrderService: RegistrationOfferOrderService,
    private registrationOfferInitiationApiService: RegistrationOfferInitiationApiService,
    private cartService: CartService,
    private loginService:LoginService
  )
   {
    if (this.router.getCurrentNavigation() != undefined && this.router.getCurrentNavigation() != null) {
      if (this.router.getCurrentNavigation().extras.state) {
        this.resultMessage = this.router.getCurrentNavigation().extras.state.result;
      }
    }
   }

  ngOnInit(): void {

    // if(this.localStorageService.get('account_error')=='true')
    // {
    //   this.localStorageService.set('account_error',"false")
    // }

    caches.keys().then(function(names) {
      for (let name of names) {
        caches.delete(name);
      }
    });
    
    if(this.config.title=='Karupattiraja')
    {
      this.customerType = "business";
      this.businessRegistration.valid==true;
    }
    else{
      this.customerType = "individual";
    }
    
    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params['params'].url) {
        this.backToUrl = params['params'].url;
      }
      if (params['params'].productId) {
        this.productId = params['params'].productId;
      }
    })

    this.shippingForm = new UntypedFormGroup({
      location: this.formBuilder.control('',[Validators.required]),
      flat_no: this.formBuilder.control('',[Validators.required]),
      landmark: this.formBuilder.control('',[]),
      city: this.formBuilder.control('',[]),
      state:this.formBuilder.control('',[]),
      country: this.formBuilder.control('',[]),
      zipcode: this.formBuilder.control('',[]),
      mobile: this.formBuilder.control('', [Validators.required, Validators.pattern(numPattern), Validators.maxLength(10), Validators.minLength(10)]),
      email: this.formBuilder.control('', [Validators.pattern(emailPattern), Validators.maxLength(50)]),
      full_name: this.formBuilder.control('', [Validators.required, Validators.pattern(namePattern), Validators.maxLength(50)]),
      default_shipping: this.formBuilder.control(false, []),
      latitude: this.formBuilder.control('', []),
      longitude: this.formBuilder.control('', [])
    })
  
    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder
    });
    this.zoom = 15;

    this.getBusinessSettings();
    this.checkoutAlerts = true;
    this.initialForm = new UntypedFormGroup({
      email: this.formBuilder.control('',[Validators.required]),
      mobile: this.formBuilder.control('',[]),
    })

    this.passwordForm = new UntypedFormGroup({
      mobileoremail: this.formBuilder.control('',[Validators.required]),
      password: this.formBuilder.control('',[Validators.required]),
    })

    this.forgotPasswordForm =  new UntypedFormGroup({
      mobileoremail: this.formBuilder.control('',[]),
      otp: this.formBuilder.control('',[Validators.required]),
      password: this.formBuilder.control('',[Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
    })

    this.otpLoginWithoutPswd = new UntypedFormGroup({
      mobileoremail: this.formBuilder.control('',[]),
      otp: this.formBuilder.control('',[Validators.required]),
      password: this.formBuilder.control('',[Validators.required,Validators.minLength(6), Validators.maxLength(20)])
    })

    this.requestOTPLoginForm = new UntypedFormGroup({
      mobileoremail: this.formBuilder.control('', []),
      otp: this.formBuilder.control('',[Validators.required])
    })

    this.updateEmailForm1 =  new UntypedFormGroup({
      email: this.formBuilder.control('', []),
      mobile: this.formBuilder.control('',[]),
      otp: this.formBuilder.control('',[Validators.required]),
      password: this.formBuilder.control('',[Validators.minLength(6), Validators.maxLength(20)])
    })

    this.updateEmailForm2 =  new UntypedFormGroup({
      email: this.formBuilder.control('', []),
      mobile: this.formBuilder.control('',[]),
      otp: this.formBuilder.control('',[Validators.required]),
    })

    this.updateEmailForm3 =  new UntypedFormGroup({
      email: this.formBuilder.control('', []),
      mobile: this.formBuilder.control('',[]),
      password: this.formBuilder.control('',[Validators.required, Validators.minLength(6), Validators.maxLength(20)])
    })


    this.initialRegistration = new UntypedFormGroup({
      mobile: this.formBuilder.control('',[]),
      email: this.formBuilder.control('', []),
      otp: this.formBuilder.control('',[Validators.required]),
      password: this.formBuilder.control('',[Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
      userType: this.formBuilder.control('',[])
    })

    this.individualRegistration = new UntypedFormGroup({
      name: this.formBuilder.control('',[Validators.required, Validators.maxLength(80), Validators.minLength(2), Validators.pattern(namePattern)]),
      email: this.formBuilder.control('', [Validators.required]),
    })
if(this.config.title=='Karupattiraja')
{
  this.businessRegistration = new UntypedFormGroup({
    name: this.formBuilder.control('',[Validators.required, Validators.maxLength(80), Validators.minLength(2), Validators.pattern(namePattern)]),
    email: this.formBuilder.control('', [Validators.pattern(emailPattern), Validators.required]),
    company: this.formBuilder.control('',[Validators.required,Validators.minLength(1), Validators.maxLength(80)]),
    company_address: this.formBuilder.control('',[Validators.required]),
    gst_no: this.formBuilder.control(''),
    city: this.formBuilder.control('',[Validators.required]),
    state: this.formBuilder.control('',[Validators.required]),
    country: this.formBuilder.control('India'),
    zipcode:this.formBuilder.control('',[Validators.required,Validators.max(999999), Validators.minLength(100000)]),
    // pan_no: this.formBuilder.control('',[Validators.required,Validators.maxLength(10), Validators.minLength(10)]),
     pan_no: this.formBuilder.control('',[Validators.maxLength(10), Validators.minLength(10)]),
  })
}
else if(this.config.title=='BestGifts'){
  this.businessRegistration = new UntypedFormGroup({
    name: this.formBuilder.control('',[Validators.required, Validators.maxLength(80), Validators.minLength(2), Validators.pattern(namePattern)]),
    email: this.formBuilder.control('', [Validators.pattern(emailPattern), Validators.required]),
    company: this.formBuilder.control('',[Validators.required,Validators.minLength(1), Validators.maxLength(80)]),
    company_address: this.formBuilder.control('',[Validators.required]),
    gst_no: this.formBuilder.control('',[Validators.maxLength(15), Validators.minLength(15)]),
    pan_no: this.formBuilder.control('',[Validators.maxLength(10), Validators.minLength(10)]),
    city: this.formBuilder.control(''),
    state: this.formBuilder.control(''),
    country: this.formBuilder.control(''),
    zipcode:this.formBuilder.control(''),
    
     //email:this.formBuilder.control(''),
  })
  

}
else{
  this.businessRegistration = new UntypedFormGroup({
    name: this.formBuilder.control('',[Validators.required, Validators.maxLength(80), Validators.minLength(2), Validators.pattern(namePattern)]),
    email: this.formBuilder.control('', [Validators.pattern(emailPattern), Validators.required]),
    company: this.formBuilder.control('',[Validators.required,Validators.minLength(1), Validators.maxLength(80)]),
    company_address: this.formBuilder.control('',[Validators.required]),
    gst_no: this.formBuilder.control('',[Validators.required,Validators.maxLength(15), Validators.minLength(15)]),
    pan_no: this.formBuilder.control('',[Validators.required,Validators.maxLength(10), Validators.minLength(10)]),
    city: this.formBuilder.control(''),
    state: this.formBuilder.control(''),
    country: this.formBuilder.control(''),
    zipcode:this.formBuilder.control(''),
    
     //email:this.formBuilder.control(''),
  })
}
    
  }
  alertshow()
{
  this.alertService.error("Your Account is in under verification.Once Verified,you can able to login", this.options);
 // this.alertService.error("Please login to View Products", this.options);                        
}
pleaselogin()
{
  //this.alertService.error("Please login to View Products", this.options);
                         
}
  ngAfterViewInit(): void {
    this.alertService.clear();
    if (this.resultMessage !== "") {
      window.scrollTo(0,0);
      this.alertService.success(this.resultMessage, this.options);
      this.cdr.detectChanges();
    }
    this.loginService.currentData.subscribe(currentData => {
      let data:any = currentData;
      if(data.name === "alert_error"){
        this.alertshow();
      }
      else if(data.name === "ask_login")
      {
this.pleaselogin();
      }
      

    })
    // if(this.localStorageService.get('account_error')=='true')
    // {
    //   this.alertshow();
    // }
    // this.localStorageService.set('account_error',"false")


  }
  // ngOnDestroy() {
  //   this.alertshow();
  // }
  // REGISTRATION OFFER
  get f11(){
    return this.shippingForm.controls;
  }

  isDeliverable(){
    return new Promise((resolve, reject) => {
      this.registrationOfferApiService.getOutletDetails().then(
        respData => {
          let res:any = respData;
          if(res){
            this.enabledistanceValidation = res.enabled;
            this.distance = res.distance;
            var latitude:number = this.pickupLatitude = res.latitude;
            var longitude:number = this.pickupLongitude = res.longitude;
            var restaurent = new google.maps.LatLng(latitude, longitude);
            var selectedLocation = new google.maps.LatLng(this.latitude, this.longitude);
            const distance = google.maps.geometry.spherical.computeDistanceBetween(restaurent, selectedLocation);
            let dimensions = distance / 1000;
            this.registrationOfferApiService.distance = dimensions
            resolve(dimensions);
          }
        })
    })
  }

  selectLocation(event) {
    this.latitude = event.coords.lat;
    this.longitude  = event.coords.lng;
    this.zoom = 15;
    this.getLocationdetails();
  }

  cancelSave(){
    this.backtoHome();
    this.shippingForm.reset();
    this.offerAvailed = false;
    this.selectedAddress = null;
    this.is_deliverable = true;
    this.location_bloked = false;
  }

  saveAddress(){
    this.saveaddSpinner = true;
    if(this.shippingForm.value.location !== this.validAddress){
      this.saveaddSpinner = false;
      this.validateShippingForm = true;
      this.shippingForm.controls['location'].setErrors({'incorrect1':true});
    }
    if(this.shippingForm.value.mobile === "0000000000"){
      this.saveaddSpinner = false;
      this.validateShippingForm = true;
      this.shippingForm.controls['mobile'].setErrors({'incorrect':true});
    }
    var re = /'/gi;
    var re1 = / /gi;
    let name = this.shippingForm.value.full_name;
    if(name !== ""){
      name = name.replace(re, "")
      name = name.replace(re1, "")
      if(name.length === 0){
        this.saveaddSpinner = false;
        this.validateShippingForm = true;
        this.shippingForm.controls['full_name'].setErrors({'incorrect':true});
      }
    }
    if(this.shippingForm.valid){
      this.validateShippingForm = false;
      this.selectedAddress = {
        customer_id:0,
        alias:"",
        first_name:this.shippingForm.value.full_name,
        last_name:"",
        company_name:"",
        entire_location:this.shippingForm.value.location,
        address1:this.shippingForm.value.flat_no,
        landmark: this.shippingForm.value.landmark,
        city: this.shippingForm.value.city,
        state: this.shippingForm.value.state,
        country: this.shippingForm.value.country,
        country_code:"104",
        zipcode: this.shippingForm.value.zipcode,
        email: this.shippingForm.value.email,
        phone_number: this.shippingForm.value.mobile,
        is_preferred_address:this.shippingForm.value.default_shipping,
        address_type:"",
        longitude:this.longitude,
        latitude:this.latitude
      }
      this.registrationOfferApiService.selectedAddress = this.selectedAddress;
      this.registrationOfferInitiationApiService.setOrderDetails().then(
        resData => {
          let resp:any = resData;
          if(resp){
            this.saveaddSpinner = false;
            this.backtoHome();
          }
        }
      );
    }
    else{
      this.saveaddSpinner = false;
      this.validateShippingForm = true;
    }
  }

  editCustomerAddress(){
    let address = this.selectedAddress;
    this.shippingForm.patchValue({
      location: address.entire_location,
      flat_no: address.address1,
      landmark: address.landmark,
      city: address.city,
      state:address.state,
      country: address.country,
      zipcode: address.zipcode,
      mobile: address.phone_number,
      email: address.email,
      full_name: address.first_name,
      default_shipping: address.is_preferred_address,
      latitude: address.latitude,
      longitude:address.longitude
    })
    this.longitude = address.longitude;
    this.latitude = address.latitude;
    this.validAddress = address.entire_location;
    this.geocoder.geocode({ 'location': { lat: address.latitude, lng: address.longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 14
        }
        else {}
      }
    })
    this.openMsgModel();
  }

getlat_long(event:any)
{
  this.geocoder.geocode({ 'address': 'zipcode '+ event.target.value  }, (results, status) => {
    if (status == google.maps.GeocoderStatus.OK) {
      this.latitude = results[0].geometry.location.lat();
         this.longitude = results[0].geometry.location.lng();
    }
  })
   
}
  addAddress(address){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "payload": {
        "create_address": address
      }
    }
    this.checkoutApiService.checkoutAPICall(ENDPOINTS.CREATE_CUSTOMER_ADDRESSES, requestData).then(
      respData => {
        let res:any = respData;
        if(res.success){

        }
      }
    )

  }

  changeAddress(address:any){
    this.shippingForm.controls.location.setValue(address.formatted_address);
    this.latitude = address.geometry.location.lat()
    this.longitude = address.geometry.location.lng()
    this.getLocationdetails();
    this.isDeliverable().then(
      respData => {
        let res:any = respData;
        if(this.enabledistanceValidation && this.distance > 0){
          if(res > this.distance){
            this.is_deliverable = false;
            this.shippingForm.controls['location'].setErrors({'incorrect':true});
          }
          else{
            this.is_deliverable = true;
          }
        }
        else{
          this.is_deliverable = true;
        }
      }
    )
  }

  getLocationdetails(){
    this.geocoder.geocode({ 'location': { lat: this.latitude, lng: this.longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 14;
          this.validAddress = results[0].formatted_address;
          this.shippingForm.controls.location.setValue(results[0].formatted_address);
          results[0].address_components.forEach(element => {
            if (element.types.includes("administrative_area_level_2")) {
              this.shippingForm.controls.city.setValue(element.long_name);
            }
            if (element.types.includes("administrative_area_level_1")) {
              this.shippingForm.controls.state.setValue(element.long_name);
            }
            if (element.types.includes("country")){
              this.shippingForm.controls.country.setValue(element.long_name);
            }
            if (element.types.includes("postal_code")){
              this.shippingForm.controls.zipcode.setValue(element.long_name);
            }
          });
          this.isDeliverable().then(
            respData => {
              let res:any = respData;
              if(this.enabledistanceValidation && this.distance > 0){
                if(res > this.distance){
                  this.is_deliverable = false;
                  this.shippingForm.controls['location'].setErrors({'incorrect':true});
                }
                else{
                  this.is_deliverable = true;
                }
              }
              else{
                this.is_deliverable = true;
              }
            }
          )
        }
      }
    })
  }

  getCurrentLocation(){
    this.longitude = null;
    this.latitude = null;
    navigator.geolocation.getCurrentPosition(position => {
      this.location_bloked = false;
      this.latitude = position.coords.latitude
      this.longitude = position.coords.longitude
      this.getLocationdetails();
    }, error => {
      if(error.code === 1){
        this.location_bloked = true;
      }
    }, {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    })

  }

  get f(){
    return this.initialForm.controls;
  }

  get f1(){
    return this.passwordForm.controls;
  }

  get f2(){
    return this.initialRegistration.controls;
  }

  get f3(){
    return this.individualRegistration.controls;
  }

  get f4(){
    return this.businessRegistration.controls;
  }

  get f5(){
    return this.forgotPasswordForm.controls;
  }

  get f6(){
    return this.otpLoginWithoutPswd.controls;
  }

  get f7(){
    return this.requestOTPLoginForm.controls;
  }

  get f8(){
    return this.updateEmailForm1.controls;
  }

  get f9(){
    return this.updateEmailForm3.controls;
  }

  get f10(){
    return this.updateEmailForm2.controls;
  }

  getBusinessSettings(){
    this.customerLoginService.getBusinessUserSettings().then(
      respData => {
        let resp:any = respData;
        this.enableBusinessUser = resp;
        // console.log("enableBusinessUser"+this.enableBusinessUser)
        // GET CHECKOUT SETTINGS
        // console.log("custType1")
        this.customerLoginService.checkoutSettings().then(
          settings => {
            let setting:any = settings;
            console.log("custType2"+setting)
            if(setting.mandatory){
              setting.customers.forEach(custType => {
                console.log("custType"+custType)
                if(custType.toUpperCase() === this.INDIVIDUAL_CUSTOMER){
                  this.is_mandatory.individual = true;
                }
                if(custType.toUpperCase() === this.BUSINESS_CUSTOMER){
                  this.is_mandatory.business = true;
                }
                if(custType === "ALL"){
                  this.is_mandatory.individual = true;
                  this.is_mandatory.business = true;
                }
              });

            }
          }
        )
      }
    )
  }

  changeForm(formType){
    if(formType === "initialstate"){
      this.initialForm.reset();
      this.validateInitialForm = false;
      this.hideMobile = true;
      this.passwordForm.reset();
      this.validatePasswordForm = false;
      this.initialRegistration.reset();
      this.validateInitialRegistration = false;
      this.screenType = "initial"
      this.otpType="";
      this.selectType=false;
      this.resetForms();
      this.registrationOfferApiService.clearOnChange();
    }
    else if(formType === 'loggedOut'){
      this.initialForm.reset();
      this.validateInitialForm = false;
      this.hideMobile = true;
      this.passwordForm.reset();
      this.validatePasswordForm = false;
      this.initialRegistration.reset();
      this.validateInitialRegistration = false;
      this.individualRegistration.reset();
      this.validateIndividualRegistration = false;
      this.businessRegistration.reset();
      this.validateBusinessRegistration = false;
      this.screenType = "loggedOut";
      this.otpType="";
      this.selectType=false;
      this.resetForms();
      this.registrationOfferApiService.clearOnChange();
    }
    else if(formType === 'forgotPassword'){

        this.forgotMobile = this.passwordForm.controls.mobileoremail.value;
        this.forgotPasswordForm.controls.mobileoremail.setValue(this.passwordForm.controls.mobileoremail.value);
        this.otpType = 'Mobile';
        this.sendOTP(this.passwordForm.controls.mobileoremail.value).then(
          respData1 => {
            console.log("ttttttt")
            let res1:any = respData1;
            if(res1){
            this.otpResendDuration = new Date().getTime() + 5*60*1000;
            this.resendMsg=this.forgotMobile

            this.alertService.success('OTP has been sent to your Registered Mobile', this.options);
              this.screenType = "forgotPassword";
            }
          }
        )
   

    }
  }

  resetForms(){
    this.initialForm.reset();
    this.passwordForm.reset();
    this.initialRegistration.reset();
    this.individualRegistration.reset();
    this.businessRegistration.reset();
    this.forgotPasswordForm.reset();
    this.validateInitialForm = false;
    this.validatePasswordForm = false;
    this.validateInitialRegistration = false;
    this.validateIndividualRegistration = false;
    this.validateBusinessRegistration = false;
    this.validateForgotPasswordForm = false;
    this.shippingForm.reset();
    this.validateShippingForm = false;
    this.customerType = "individual";
    this.offerAvailed = false;
    this.location_bloked = false;
    this.is_deliverable = true;
    this.pickupLatitude = undefined;
    this.pickupLongitude = undefined;
    this.offerAvailed = false;
    this.latitude = undefined;
    this.longitude = undefined;
    // this.zoom = undefined;
    // this.geocoder = undefined;
    this.enabledistanceValidation = false;
    this.distance = 0;
    this.validAddress = "";
    this.selectedAddress = null;
  }

  verifyUserDetails(){

    this.continue1 = true;
    // var re_mobile = new RegExp(numPattern);
    // if(re_mobile.test(this.initialForm.controls.mobile.value)){
      if(this.initialForm.controls.mobile.value.length !== 10){
        this.continue1 = false;
        this.validateInitialForm = true;
        this.initialForm.controls['mobile'].setErrors({'incorrect':true});
      }
      else{
          this.getUserDetails(this.initialForm.controls.mobile.value, 'mobile').then(
            respDt => {
              console.log("getUserDetails1")
              let respData:any = respDt;
              if(respData.exist){
                this.validateInitialForm = true;
                if(respData.email){
                  this.continue1 = false;
                  this.initialForm.controls['mobile'].setErrors({'exists':true});
                }
                else{
                  if(respData.password){
                    this.updateEmailForm3.controls.email.setValue(this.initialForm.controls.email.value);
                    this.updateEmailForm3.controls.mobile.setValue(this.initialForm.controls.mobile.value);
                    this.screenType = "updateEmailForm3";
                    this.continue1 = false;
                  }
                  else{
                    this.updateEmailForm1.controls.email.setValue(this.initialForm.controls.email.value);
                    this.updateEmailForm1.controls.mobile.setValue(this.initialForm.controls.mobile.value);
                    this.sendOTPToEmail(this.initialForm.controls.email.value).then(
                      respData => {
                        let res:any = respData;
                        if(res){
                          this.screenType = "updateEmailForm1";
                          this.continue1 = false;
                          this.otpResendDuration = new Date().getTime() + 5*60*1000;
                          this.resendMsg=this.initialForm.controls.email.value;
                          
                          this.alertService.success('OTP has been sent to your Registered Email', this.options);
                        }
                      }
                    )
                  }
                }

              }
              else{
                this.registrationOfferApiService.getRegistrationOffer().then(
                  regData => {
                    let regRes:any = regData;
                    if(regRes){
                      this.registrationOfferDetails = regRes;
                    }
                  }
                )
                this.initialRegistration.controls.mobile.setValue(this.initialForm.controls.mobile.value);
                this.initialRegistration.controls.email.setValue(this.initialForm.controls.email.value);
                this.sendOTP(this.initialForm.controls.mobile.value).then(
                  respData => {
                    console.log("ttttttt")
                    if(respData){
                      this.hideMobile = true;
                      this.screenType = "initialRegistration";
                      this.continue1 = false;
                      this.otpResendDuration = new Date().getTime() + 5*60*1000;
                      this.resendMsg=this.initialRegistration.controls.mobile.value;
                      this.alertService.success('OTP has been sent to your Registered Email', this.options);
                    }
                  }
                )
              }
            }
          )
      }
    // }
    // else{
    //   this.continue1 = false;
    //   this.validateInitialForm = true;
    //   this.initialForm.controls['mobile'].setErrors({'incorrect':true});
    // }
  }

  verifyUser(){
    if(this.initialForm.controls.email.value === "" || this.initialForm.controls.email.value === undefined || this.initialForm.controls.email.value === null){
     console.log("daatt")
      this.continue1 = false;
      this.validateInitialForm = true;
      this.IECNo = true;
      // this.initialForm.controls['email'].setErrors({'required':true});

    }  else {
      if(this.config.title=='Karupattiraja')
        {
          this.customerType = "business";
          this.businessRegistration.valid==true;
        }
        else{
          this.customerType = "individual";
        }
        
        console.log("verify user")
        this.IECNo = false;
        // if(this.initialForm.valid){
          this.continue2 = true;
              if(this.initialForm.controls.email.value.length !== 10){
                this.continue2 = false;
                this.validateInitialForm = true;
                this.initialForm.controls['email'].setErrors({'incorrect':true});
                console.log("uuuuuuuuuuuuuuuuu")
                this.ValidIECNo = true;
              }
              else{
                this.loggedinAs = "";
                this.userIdType = "mobile";
                this.ValidIECNo = false;
                this.getUserDetails(this.initialForm.controls.email.value, 'mobile').then(
                  respDatas => {
                    console.log("getUserDetails3")
                    let respData:any = respDatas;
                    console.log("getUserDetails3"+ JSON.stringify(respData));
                    if(respData.exist){
                      if(this.config.title!=='AvonSeals'){
                        if(respData.password){
                          this.screenType = "login";
                          this.continue2 = false;
                          this.passwordForm.controls.mobileoremail.setValue(this.initialForm.controls.email.value);
                        }
                      else{
                        this.sendOTP(this.initialForm.controls.email.value).then(
                          respData => {
                            console.log("ttttttt")
                            if(respData){
                              this.continue2 = false;
                              this.screenType = "otploginwithoutpswd";
                              this.otpLoginWithoutPswd.controls.mobileoremail.setValue(this.initialForm.controls.email.value);
                              this.otpResendDuration = new Date().getTime() + 5*60*1000;
                              this.resendMsg=this.initialForm.controls.email.value;
                              this.alertService.success('OTP has been sent to your Registered Email', this.options);
                            }
                          }
                        )
                      }
                      }
                      if(this.config.title==='AvonSeals' && this.type==='business customer'){
                        //                    if(respData.password){
                        //   this.screenType = "login";
                        //   this.continue2 = false;
                        //   this.passwordForm.controls.mobileoremail.setValue(this.initialForm.controls.email.value);
                        // }
                      // else{
                        this.sendOTP(this.initialForm.controls.email.value).then(
                          respData => {
                            console.log("ttttttt")
                            if(respData){
                              this.continue2 = false;
                              this.screenType = "otploginwithoutpswd";
                              this.otpLoginWithoutPswd.controls.mobileoremail.setValue(this.initialForm.controls.email.value);
                              this.otpResendDuration = new Date().getTime() + 5*60*1000;
                              this.resendMsg=this.initialForm.controls.email.value;
                              this.alertService.success('OTP has been sent to your Registered Email', this.options);
                            }
                          }
                        )
                      // }
                      }
                      if(this.config.title==='AvonSeals' && this.type==='individual customer'){
                        if(respData.password){
                          this.screenType = "login";
                          this.continue2 = false;
                          this.passwordForm.controls.mobileoremail.setValue(this.initialForm.controls.email.value);
                        }
                      else{
                        this.sendOTP(this.initialForm.controls.email.value).then(
                          respData => {
                            console.log("ttttttt")
                            if(respData){
                              this.continue2 = false;
                              this.screenType = "otploginwithoutpswd";
                              this.otpLoginWithoutPswd.controls.mobileoremail.setValue(this.initialForm.controls.email.value);
                              this.otpResendDuration = new Date().getTime() + 5*60*1000;
                              this.resendMsg=this.initialForm.controls.email.value;
                              this.alertService.success('OTP has been sent to your Registered Email', this.options);
                            }
                          }
                        )
                      }
                      }
    
                    }
                    else{
                      this.registrationOfferApiService.getRegistrationOffer().then(
                        regData => {
                          let regRes:any = regData;
                          if(regRes){
                            this.registrationOfferDetails = regRes;
                          }
                        }
                      )
                      this.initialRegistration.controls.mobile.setValue(this.initialForm.controls.email.value);
                      this.screenType = "initialRegistration";
                      this.selectType = true;
                      // this.sendOTP(this.initialForm.controls.email.value).then(
                      //   respData => {
                      //     console.log("ttttttt")
                      //     if(respData){
                      //       this.hideMobile = true;
                      //       this.continue2 = false;
                      //       this.screenType = "initialRegistration";
                      //       this.otpResendDuration = new Date().getTime() + 5*60*1000;
                      //       this.resendMsg=this.initialRegistration.controls.mobile.value
                      //       this.alertService.success('OTP has been sent to your Registered Email', this.options);
                      //     }
                      //   }
                      // )
                    }
                  }
                )
              }
            
          // else{
          //   this.continue2 = false;
          //     this.validateInitialForm = true;
          //     this.initialForm.controls['email'].setErrors({'incorrect':true});
          //   }
          // }
        // else{
        //   this.validateInitialForm = true;
        // }
    } 
  }

  getUserDetails(mobileorEmail, type){
    return new Promise((resolve, reject) => {
      let reqData = {}
      if(type === "mobile"){
        reqData = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras": {
              "find": {
                  "ie_code": mobileorEmail.toString()
              }
          }
        }
      }

      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, reqData).then(
        respData => {
          let res:any = respData;
          console.log("data1"+JSON.stringify(res));
          if(res.data.length===0)
          {
           this.IncorrectIECNo=true;
          } else {
            console.log("dddddddddddddddddd")
            this.IncorrectIECNo=false;
            // if(res.success){
              let user = {
                exist:false,
                password:false,
                email:false
              }
              this.localStorageService.set('CleCusomerid', res.data[0].id);
              this.cleMobileNumber = res.data[0].mobile_number
              this.cleEmail = res.data[0].email
              this.localStorageService.set('CleIECode', res.data[0].ie_code);
              console.log("dddddddddddddddddd"+res.data[0].company_name)
              this.localStorageService.set('CompanyName', res.data[0].company_name);
              this.localStorageService.set('CompanyEmail', res.data[0].email);
              this.localStorageService.set('CompanyTelephone', res.data[0].phone_number);
              this.localStorageService.set('CompanyMobileNumber', res.data[0].mobile_number);
              this.localStorageService.set('CompanyFOBValue', res.data[0].fob_value);
              this.localStorageService.set('CleJoinDate', res.data[0].joined_date);
              this.id = res.data[0].id;
              // if(res.data.length > 0){
                if(res.data[0].password && res.data[0].password !== ""){
                  this.selectType = false;
                user.exist = true;
                this.type = res.data[0].customer_type;
                // if(res.data[0].password && res.data[0].password !== ""){
                  user.password = true;
                // }
                // if(res.data[0].email && res.data[0].email !== ""){
                //   user.email = true;
                // }
                resolve(user)
              }
              else{
                resolve(user);
              }
            // }
          }

      
        }
      )
    })
  }

  sendOTPToEmail(email){
    return new Promise((resolve, reject) => {
      // let requestData = {
      //   "domain_name": ORG_DETAILS.DOMAIN_NAME,
      //   "data": {
      //       "otp": {
      //           "email": email
      //       }
      //   }
      // }
      // this.checkoutApiService.checkoutAPICall(ENDPOINTS.SEND_OTP_EMAIL, requestData).then(
      //   respData => {
      //     let res:any = respData;
      //     if(res.success === 1){
      //       resolve(true);
      //     }
      //     else{
      //       resolve(false);
      //     }
      //   }
      // )
    })
  }

  // sendOTP(mobileNo){
  //   return new Promise((resolve, reject) => {
  //     let requestData = {
  //       "domain_name": ORG_DETAILS.DOMAIN_NAME,
  //       "data": {
  //           "otp": {
  //               "countrycode": "+91",
  //               "mobile": this.cleMobileNumber,
  //               "otppurpose": "login"
  //           }
  //       }
  //     }
  //     this.checkoutApiService.checkoutAPICall(ENDPOINTS.SEND_OTP, requestData).then(
  //       respData => {
  //         let res:any = respData;
  //         if(res.success === 1){
  //           resolve(true);
  //         }
  //         else{
  //           resolve(false);
  //         }
  //       }
  //     )
  //   })
  // }

  sendOTP(mobileNo){
    return new Promise((resolve, reject) => {
      console.log("ffffffffffffffffff"+this.otpType)
      if(this.otpType=="Email"){
        let requestData = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "data": {
              "otp": {
                  "email": this.cleEmail
              }
          }
        }
        this.checkoutApiService.checkoutAPICall(ENDPOINTS.SEND_OTP_EMAIL, requestData).then(
          respData => {
            let res:any = respData;
            if(res.success === 1){
              resolve(true);
              this.selectType = false;
              this.hideMobile = true;
              this.continue2 = false;
              this.screenType = "initialRegistration";
              this.otpResendDuration = new Date().getTime() + 5*60*1000;
              this.resendMsg=this.initialRegistration.controls.mobile.value
              this.alertService.success('OTP has been sent to your Registered Email', this.options);
            }
            else{
              resolve(false);
            }
          }
        )
      } else if(this.otpType=="Mobile"){
        let requestData = {
                "domain_name": ORG_DETAILS.DOMAIN_NAME,
                "data": {
                    "otp": {
                        "countrycode": "+91",
                        "mobile": this.cleMobileNumber,
                        "otppurpose": "login"
                    }
                }
              }
              this.checkoutApiService.checkoutAPICall(ENDPOINTS.SEND_OTP, requestData).then(
                respData => {
                  let res:any = respData;
                  if(res.success === 1){
                    resolve(true);
                    this.selectType = false;
                    this.hideMobile = true;
                    this.continue2 = false;
                    this.screenType = "initialRegistration";
                    this.otpResendDuration = new Date().getTime() + 5*60*1000;
                    this.resendMsg=this.initialRegistration.controls.mobile.value
                    this.alertService.success('OTP has been sent to your Registered Mobile', this.options);
                  }
                  else{
                    resolve(false);
                  }
                }
              )
      } else if(this.otpType==undefined){
      this.selectType = true;
      this.screenType = "initialRegistration";
      }

    })
  }

  showLoginPassword(){
    this.showLoginpaswd = !this.showLoginpaswd;
  }

  showRegistrationPassword(){
    this.showregistrationPswd = !this.showregistrationPswd;
  }

  showForgotPassword(){
    this.showForgotPswd = !this.showForgotPswd;
  }

  showOTPWithoutPswd(){
    this.showotpwithoutpswd = !this.showotpwithoutpswd;
  }

  showUpdateEmailForm1M(){
    this.showUpdateEmailForm1 = !this.showUpdateEmailForm1;
  }

  showUpdateEmailForm3M(){
    this.showUpdateEmailForm3 = !this.showUpdateEmailForm3;
  }

  resendOTP(){
    this.sendOTP(this.initialRegistration.controls.mobile.value).then(
      respData => {
        console.log("ttttttt")
        let res:any = respData;
        if(res){
          this.otpResendDuration = new Date().getTime() + 5*60*1000;
          this.resendMsg=this.initialRegistration.controls.mobile.value;
          this.alertService.success('OTP has been sent to your Registered Email', this.options);
        }
      }
    )
  }
  resendCode(resendType){

    if(this.otpResendDuration === undefined || this.otpResendDuration < new Date().getTime())
    {
      if(resendType == 'otplogin2'){
      this.resendOTPTo('otplogin2')
      }
      else if(resendType== 'otplogin1'){
      this.resendOTPTo('otplogin1')
      }
      else if(resendType== 'updateEmail1'){
        this.resendOTPTo('updateEmail1') 
        }
      else if(resendType== 'updateEmail2'){
        this.resendOTPTo('updateEmail2') 
        }
      else if(resendType== 'otp1'){
        this.changeForm('forgotPassword')

        }
      else if(resendType== 'forgot'){
        this.changeForm('forgotPassword')
         }
     }
    else
    {

        if(resendType == 'otplogin2'){
          if(this.otpType=='Email'){
            this.alertService.error("OTP has been already sent to Registered Email", this.options)
          } else {
            this.alertService.error("OTP has been already sent to Registered Mobile", this.options)
          }
        

        }
        else if(resendType== 'otplogin1'){
          if(this.otpType=='Email'){
            this.alertService.error("OTP has been already sent to Registered Email", this.options)
          } else {
            this.alertService.error("OTP has been already sent to Registered Mobile", this.options)
          }
        }
        else if(resendType== 'updateEmail1'){
          this.alertService.error("OTP has been already sent to Registered Email", this.options)

          }
        else if(resendType== 'updateEmail2'){
          this.alertService.error("OTP has been already sent to Registered Email",this.options)
          }
        else if(resendType== 'otp1'){
            this.alertService.error("OTP has been already sent to Registered Email", this.options)
            }
        else if(resendType== 'forgot'){
              this.alertService.error("OTP has been already sent to Registered Email", this.options)
              }
        }
  }
  resendOTPTo(receiverField){
    if(receiverField === "otplogin1"){
 
     
        this.sendOTP(this.cleEmail).then(
          respData => {
            console.log("ttttttt")
            let res:any = respData;
            if(res){
              this.otpResendDuration = new Date().getTime() + 5*60*1000;
              this.resendMsg=this.otpLoginWithoutPswd.controls.mobileoremail.value
              this.alertService.success('OTP has been sent to your Registered Email', this.options);
            }
          }
        )
      
    }
    else if(receiverField === "otplogin2"){

   
        this.sendOTP(this.cleEmail).then(
          respData => {
            console.log("ttttttt")
            let res:any = respData;
            if(res){
              this.otpResendDuration = new Date().getTime() + 5*60*1000;
              this.resendMsg=this.requestOTPLoginForm.controls.mobileoremail.value;
              this.alertService.success('OTP has been sent to your Registered Email', this.options);
            }
          }
        )
      
    }
  }

  // verifyOTP(mobile, otp){
  //   return new Promise((resolve, reject) => {
  //   let requestData = {
  //     "domain_name": ORG_DETAILS.DOMAIN_NAME,
  //     "data": {
  //         "otp": {
  //             "userotp": otp,
  //             "mobile": this.cleMobileNumber
  //         }
  //       }
  //     }
  //   this.checkoutApiService.checkoutAPICall(ENDPOINTS.VERIFY_OTP, requestData).then(
  //     respdata => {
  //       let res:any = respdata;
  //       if(res.success === 1){
  //         resolve({status:true, msg:res.message});
  //       }
  //       else{
  //         resolve({status:false, msg:res.message});
  //       }
  //   })
  //   })
  // }

  verifyOTP(mobile, otp){
    return new Promise((resolve, reject) => {
      if(this.otpType=="Email"){
        let requestData = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "data": {
              "otp": {
                // "email": this.cleEmail,
                "mobile": this.cleEmail,
                "userotp": otp
              }
            }
          }
        this.checkoutApiService.checkoutAPICall(ENDPOINTS.VERIFY_OTP, requestData).then(
          respdata => {
            let res:any = respdata;
            if(res.success === 1){
              resolve({status:true, msg:res.message});
            }
            else{
              resolve({status:false, msg:res.message});
            }
        })
      } else if(this.otpType=="Mobile"){
        let requestData = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "data": {
              "otp": {
                "mobile": this.cleMobileNumber,
                "userotp": otp
              }
            }
          }
        this.checkoutApiService.checkoutAPICall(ENDPOINTS.VERIFY_OTP, requestData).then(
          respdata => {
            let res:any = respdata;
            if(res.success === 1){
              resolve({status:true, msg:res.message});
            }
            else{
              resolve({status:false, msg:res.message});
            }
        })
      }    
    })
  }

  verifyOTPEmail(email, otp){
    return new Promise((resolve, reject) => {
      // let requestData = {
      //   "domain_name": ORG_DETAILS.DOMAIN_NAME,
      //   "data": {
      //       "otp": {
      //         "email": email,
      //         "userotp": otp
      //       }
      //     }
      //   }
      // this.checkoutApiService.checkoutAPICall(ENDPOINTS.VERIFY_OTP, requestData).then(
      //   respdata => {
      //     let res:any = respdata;
      //     if(res.success === 1){
      //       resolve({status:true, msg:res.message});
      //     }
      //     else{
      //       resolve({status:false, msg:res.message});
      //     }
      // })
      })
  }

  getUserType(val){
    this.registrationOfferApiService.clearFreeSample();
    this.customerType = val;
  }

  signinWithOtp(){
    if(this.initialRegistration.valid){
      this.validateInitialRegistration = false;
      this.validateIndividualRegistration = false;
      console.log("verifyOTP1")
      this.verifyOTP(this.initialRegistration.controls.mobile.value, this.initialRegistration.controls.otp.value).then(
        respData => {
          let res:any = respData;
          if(res.status){
            let customer_type;
            let requestData = {
              "domain_name": ORG_DETAILS.DOMAIN_NAME,
              username: this.initialRegistration.controls.mobile.value,
              password: this.initialRegistration.controls.password.value,
              email: this.initialRegistration.controls.email.value,
              customer_type: this.customerType === "individual" ? "individual customer" : "business customer"
            }
            this.checkoutApiService.checkoutAPICall(ENDPOINTS.OTP_LOGIN, requestData).then(
              respData1 => {
                let res1:any = respData1;
                console.log("data444")
                if(res1.success === 1){
                  let PasswordFormData = {
                    domain_name: ORG_DETAILS.DOMAIN_NAME,
                    user_id: ORG_DETAILS.USER_ID,
                    payload: {​​​​​​​​  
                      password_details: {
                        new_password: this.initialRegistration.controls.password.value,
                      }
                      ​​​​​​​​}​​​​​​​​,
                    extras: {
                      find: {
                        id : this.id
                      },
                  }
                }
            
            
             
                  this.appService.postApiCall(PasswordFormData, ENDPOINTS.CHANGE_PASSWORD).subscribe(resp => {
                    if (resp) {
                      // console.log(resp);
          
                    }
                  }
                  )
                  this.customerLoginStorageService.setCustomerData({
                    type: this.customerType === "individual" ? "indlividual customer" : "business customer",
                    userid:res1.user.id,
                    userName:res1.user.name ? res1.user.name:"",
                    mobileNo: this.initialRegistration.controls.mobile.value ? this.initialRegistration.controls.mobile.value:"",
                    email: this.initialRegistration.controls.email.value ? this.initialRegistration.controls.email.value : "",
                    subscribed:false,
                    logintype:this.loggedinAs,
                    company_address : res1.company_address,
                    company_name: res1.company_name,
                    gst_number:res1.gst_number
                   
                  })
                  this.customerLoginStorageService.setCustomerObject({
                    customer_id:res1.user.id,
                    customer_name:res1.user.name,
                    phone_number: this.initialRegistration.controls.mobile.value,
                    is_email_opt_in:false
                  })
                  if(res1.user.name==undefined||res1.user.name==null||res1.user.name==''|| res1.user.name=='undefined'||res1.user.name=='undefined '){
                    console.log("111")
                    // this.setUserData(res1.user.id, res1.user.domain_name, this.initialRegistration.controls.mobile.value, res1.user.phoneNumber);
                    this.setUserData(res1.user.id, res1.user.domain_name, this.initialRegistration.controls.mobile.value, res1.user.phoneNumber,res1.user.customer_group,res1.user.outlet_id);
                    
                  }
                  else{
                    console.log("222")
                    // this.setUserData(res1.user.id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber);
                    this.setUserData(res1.user.id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber,res1.user.customer_group,res1.user.outlet_id);

                  }
                  //this.setUserData(res1.user.id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber);
                  this.emailormobile = this.initialRegistration.controls.mobile.value;
                  this.userIdType = "mobile";
                  this.screenType = "loggedIn";
                  this.resetForms();
                }
                else{
                  this.alertService.error(res1.message, this.options);
                }

              }
            )
          }
          else{
            this.alertService.error(res.msg, this.options);
          }
        }
      )
    }
    else{
      this.validateInitialRegistration = true;
      this.validateIndividualRegistration = true;
    }
  }

  signinupdateEmailForm1(){
    if(this.updateEmailForm1.valid){
      this.validateUpdateEmailForm1 = false;
      this.verifyOTPEmail(this.updateEmailForm1.controls.email.value, this.updateEmailForm1.controls.otp.value).then(
        respData => {
          let res:any = respData;
          if(res.status){
            let requestData = {
              "domain_name": ORG_DETAILS.DOMAIN_NAME,
              username: this.updateEmailForm1.controls.mobile.value,
              password: this.updateEmailForm1.controls.password.value,
              email: this.updateEmailForm1.controls.email.value,
            }
            this.checkoutApiService.checkoutAPICall(ENDPOINTS.OTP_LOGIN, requestData).then(
              respData1 => {
                let res1:any = respData1;
                if(res1.success === 1){
                  let reqData = {
                    "domain_name": ORG_DETAILS.DOMAIN_NAME,
                    "user_id": ORG_DETAILS.USER_ID,
                    "extras": {
                        "find": {
                            "phone_number": this.updateEmailForm1.controls.mobile.value
                        }
                    }
                  }
                  this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, reqData).then(
                    respData2 => {
                      console.log("data2")
                      let res2:any = respData2;
                      console.log("data555")
                      if(res2.success){
                        if(res2.data.length > 0){
                          this.screenType = "loggedIn";
                          this.userIdType = "mobile";
                          this.emailormobile = this.updateEmailForm1.controls.mobile.value;
                          let subscribed = false;
                          if(res2.data[0].has_also_subscribed){
                            subscribed = res2.data[0].has_also_subscribed;
                          }
                          this.customerLoginStorageService.setCustomerData({
                            type:res2.data[0].customer_type,
                            userid:res2.data[0].id,
                            userName:res1.user.name ? res1.user.name :"",
                            mobileNo: res2.data[0].phone_number ? res2.data[0].phone_number : "",
                            email: res2.data[0].email ? res2.data[0].email : "",
                            subscribed:subscribed,
                            logintype:this.loggedinAs,
                            company_address : res2.data[0].company_address,
                            company_name: res2.data[0].company_name,
                            gst_number:res2.data[0].gst_number
                          })
                          this.localStorageService.set('AiemaUserName', res2.data[0].name);
                          this.customerLoginStorageService.setCustomerObject({
                            customer_id:res2.data[0].id,
                            customer_name:res1.user.name,
                            phone_number:res2.data[0].phone_number,
                            is_email_opt_in:subscribed
                          })
                          // if(this.config.title==='AvonSeals' && res2.data[0].customer_type==='business customer'){
                          //   console.log("dataaaaaaaaaaaaaaa")
                          //  this.requesttogetOTP();
                          // }
                          if(res1.user.name==undefined||res1.user.name==null||res1.user.name==''|| res1.user.name=='undefined'){
                            // this.setUserData(res2.data[0].id, res1.user.domain_name, this.initialRegistration.controls.mobile.value, res1.user.phoneNumber);
                            console.log("111")
                            this.setUserData(res2.data[0].id, res1.user.domain_name, this.initialRegistration.controls.mobile.value, res1.user.phoneNumber,res1.user.customer_group,res1.user.outlet_id);
                          }
                          else{
                            // this.setUserData(res2.data[0].id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber);
                            console.log("111")
                            this.setUserData(res2.data[0].id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber,res1.user.customer_group,res1.user.outlet_id);
                          }


                          //this.setUserData(res2.data[0].id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber);
                        }
                      }
                    })
                }
                else{
                  this.alertService.error(res1.message, this.options);
                }
              }
            )
          }
          else{
            this.alertService.error(res.msg, this.options);
          }
        })
    }
    else{
      this.validateUpdateEmailForm1 = true;
    }
  }

  signinupdateEmailForm3(){
    if(this.updateEmailForm3.valid){
      this.validateUpdateEmailForm3 = false;
      let requestData = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        email: this.updateEmailForm3.controls.email.value,
        username: this.updateEmailForm3.controls.mobile.value+"-"+ORG_DETAILS.DOMAIN_NAME,
        password: this.updateEmailForm3.controls.password.value
      }
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.LOGIN, requestData).then(
        respData => {
          let res:any = respData;
          console.log("data333")
          if(res.success === 1){
            this.screenType = "loggedIn";
            this.userIdType = "mobile";
            this.emailormobile = this.updateEmailForm3.controls.mobile.value;
            let requestData1 = {
              "domain_name": ORG_DETAILS.DOMAIN_NAME,
              "user_id": ORG_DETAILS.USER_ID,
              "extras": {
                  "find": {
                      "phone_number": this.updateEmailForm3.controls.mobile.value
                  }
              }
            }
            this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, requestData1).then(
              respData1 => {
                console.log("data3")
                let res1:any = respData1;
                // console.log("data111")
                if(res1.success){
                  let subscribed = false;
                  if(res1.data[0].has_also_subscribed){
                    subscribed = res1.data[0].has_also_subscribed;
                  }
                  this.customerLoginStorageService.setCustomerData({
                    type:res1.data[0].customer_type,
                    userid:res1.data[0].id,
                    userName:res.user.name ? res.user.name : "",
                    mobileNo: res1.data[0].phone_number ? res1.data[0].phone_number : "",
                    email: res1.data[0].email ? res1.data[0].email : "",
                    subscribed:subscribed,
                    logintype:this.loggedinAs,
                    company_address :  res1.data[0].company_address,
                    company_name:  res1.data[0].company_name,
                    gst_number: res1.data[0].gst_number
                  })
                  this.customerLoginStorageService.setCustomerObject({
                    customer_id:res1.data[0].id,
                    customer_name:res.user.name,
                    phone_number:res1.data[0].phone_number,
                    is_email_opt_in:subscribed
                  })
                  if(res.user.name==undefined||res.user.name==null||res.user.name==''|| res.user.name=='undefined'){
                    console.log("111")
                    // this.setUserData(res1.data[0].id, res.user.domain_name, this.initialRegistration.controls.mobile.value, res.user.phoneNumber);
                    this.setUserData(res1.data[0].id, res.user.domain_name, this.initialRegistration.controls.mobile.value, res.user.phoneNumber,res.user.customer_group,res1.user.outlet_id);

                  }
                  else{
                    // this.setUserData(res1.data[0].id, res.user.domain_name, res.user.name, res.user.phoneNumber);
                    console.log("111")
                    this.setUserData(res1.data[0].id, res.user.domain_name, res.user.name, res.user.phoneNumber,res.user.customer_group,res.user.outlet_id);

                  }
                  //this.setUserData(res1.data[0].id, res.user.domain_name, res.user.name, res.user.phoneNumber);
                }
              }
            )
            this.resetForms();
          }
          else{
            this.alertService.error(res.message, this.options);
          }
        })
    }
    else{
      this.validateUpdateEmailForm3 = true;
    }
  }

  signinupdateEmailForm2(){
    this.validateUpdateEmailForm2 = false;
    if(this.updateEmailForm2.valid){
      this.verifyOTPEmail(this.updateEmailForm2.controls.email.value, this.updateEmailForm2.controls.otp.value).then(
        respData => {
          let res:any = respData;
          if(res.status){
            let requestData = {
              "domain_name": ORG_DETAILS.DOMAIN_NAME,
              username: this.updateEmailForm2.controls.mobile.value,
              email: this.updateEmailForm2.controls.email.value,
            }
            this.checkoutApiService.checkoutAPICall(ENDPOINTS.OTP_LOGIN, requestData).then(
              respData1 => {
                let res1:any = respData1;
                if(res1.success === 1){
                  let reqData = {
                    "domain_name": ORG_DETAILS.DOMAIN_NAME,
                    "user_id": ORG_DETAILS.USER_ID,
                    "extras": {
                        "find": {
                            "phone_number": this.updateEmailForm2.controls.mobile.value
                        }
                    }
                  }
                  this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, reqData).then(
                    respData2 => {
                      console.log("data4")
                      let res2:any = respData2;
                      console.log("data666")
                      if(res2.success){
                        if(res2.data.length > 0){
                          this.screenType = "loggedIn";
                          this.userIdType = "mobile";
                          this.emailormobile = this.updateEmailForm2.controls.mobile.value;
                          let subscribed = false;
                          if(res2.data[0].has_also_subscribed){
                            subscribed = res2.data[0].has_also_subscribed;
                          }
                          this.customerLoginStorageService.setCustomerData({
                            type:res2.data[0].customer_type,
                            userid:res2.data[0].id,
                            userName:res1.user.name ? res1.user.name : "",
                            mobileNo: res2.data[0].phone_number ? res2.data[0].phone_number : "",
                            email: res2.data[0].email ? res2.data[0].email : "",
                            subscribed:subscribed,
                            logintype:this.loggedinAs,
                            company_address :  res2.data[0].company_address,
                            company_name:  res2.data[0].company_name,
                            gst_number: res2.data[0].gst_number
                          })
                          this.localStorageService.set('AiemaUserName', res2.data[0].name);
                          this.customerLoginStorageService.setCustomerObject({
                            customer_id:res2.data[0].id,
                            customer_name:res1.user.name,
                            phone_number:res2.data[0].phone_number,
                            is_email_opt_in:subscribed
                          })
                          if(res1.user.name==undefined||res1.user.name==null||res1.user.name==''|| res1.user.name=='undefined'){
                            console.log("111")
                            // this.setUserData(res2.data[0].id, res1.user.domain_name, this.initialRegistration.controls.mobile.value, res1.user.phoneNumber);
                            this.setUserData(res2.data[0].id, res1.user.domain_name, this.initialRegistration.controls.mobile.value, res1.user.phoneNumber,res1.user.customer_group,res1.user.outlet_id);

                          }
                          else{
                            console.log("111")
                            // this.setUserData(res2.data[0].id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber);
                            this.setUserData(res2.data[0].id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber,res1.user.customer_group,res1.user.outlet_id);


                          }

                          //this.setUserData(res2.data[0].id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber);
                        }
                      }
                    })
                }
                else{
                  this.alertService.error(res1.message, this.options);
                }
              }
            )
          }
          else{
            this.alertService.error(res.msg, this.options);
          }
        })
    }
    else{
      this.validateUpdateEmailForm2 = true;
    }
  }

  signinwithOTPWTPassword(){
    if(this.otpLoginWithoutPswd.valid){
      console.log("verifyOTP2")
        this.verifyOTP(this.otpLoginWithoutPswd.controls.mobileoremail.value, this.otpLoginWithoutPswd.controls.otp.value).then(
          respData => {
            let res:any = respData;
            if(res.status){
              let requestData = {
                "domain_name": ORG_DETAILS.DOMAIN_NAME,
                password: this.otpLoginWithoutPswd.controls.password.value,
                username: this.otpLoginWithoutPswd.controls.mobileoremail.value,
              }
              this.checkoutApiService.checkoutAPICall(ENDPOINTS.OTP_LOGIN, requestData).then(
                respData1 => {
                  let res1:any = respData1;
                  if(res1.success === 1){
                    console.log("data999")
                    let reqData = {
                      "domain_name": ORG_DETAILS.DOMAIN_NAME,
                      "user_id": ORG_DETAILS.USER_ID,
                      "extras": {
                          "find": {
                              "phone_number": this.otpLoginWithoutPswd.controls.mobileoremail.value
                          }
                      }
                    }
                    this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, reqData).then(
                      respData2 => {
                        console.log("data6")
                        let res2:any = respData2;
                        if(res2.success){
                          if(res2.data.length > 0){

                            let status_approved=true;
                            //status approved checks only for carience and karupatti
                                        if(this.config.title=='Carience'|| this.config.title=='Karupattiraja')
                                        {
                                          if(res2.data[0].status!="Approved")
                                          {
                                            status_approved=false;
                                          }
                            
                                        }
                                       if(status_approved)
                                       {

                                        console.log("data1010")
                                        this.screenType = "loggedIn";
                                        this.emailormobile = this.otpLoginWithoutPswd.controls.mobileoremail.value;
                                        let subscribed = false;
                                        if(res2.data[0].has_also_subscribed){
                                          subscribed = res2.data[0].has_also_subscribed;
                                        }
                                        this.customerLoginStorageService.setCustomerData({
                                          type:res2.data[0].customer_type,
                                          userid:res2.data[0].id,
                                          userName:res1.user.name ? res1.user.name:"",
                                          mobileNo: res2.data[0].phone_number ? res2.data[0].phone_number : "",
                                          email: res2.data[0].email ? res2.data[0].email : "",
                                          subscribed:subscribed,
                                          logintype:this.loggedinAs,
                                          company_address :  res2.data[0].company_address,
                                          company_name:  res2.data[0].company_name,
                                          gst_number: res2.data[0].gst_number
                                        })
                                        this.localStorageService.set('AiemaUserName', res2.data[0].name);
                                        this.customerLoginStorageService.setCustomerObject({
                                          customer_id:res2.data[0].id,
                                          customer_name:res1.user.name,
                                          phone_number:res2.data[0].phone_number,
                                          is_email_opt_in:subscribed
                                        })
                                        if(res1.user.name==undefined||res1.user.name==null||res1.user.name==''|| res1.user.name=='undefined'){
            
                                          console.log("111")
                                          // this.setUserData(res2.data[0].id, res1.user.domain_name, this.initialRegistration.controls.mobile.value, res1.user.phoneNumber);
                                          this.setUserData(res2.data[0].id, res1.user.domain_name, this.initialRegistration.controls.mobile.value, res1.user.phoneNumber,res1.user.customer_group,res1.user.outlet_id);
            
            
                                        }
                                        else{
                                          // this.setUserData(res2.data[0].id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber)
                                          console.log("111");
                                          this.setUserData(res2.data[0].id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber,res1.user.customer_group,res1.user.outlet_id);
            
                                        }
                                       }
                                       else{
                                        this.screenType='login'
                                        this.passwordForm.controls['mobileoremail'].setValue( this.otpLoginWithoutPswd.controls.mobileoremail.value, {onlySelf: true});
                                        this.alertService.error("Your Account is in under verification.Once Verified,you can able to login", this.options);
                          
                                       }
                           
                            //this.setUserData(res2.data[0].id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber);
                          }
                        }
                      })
                  }
                  else{
                    this.alertService.error(res1.message, this.options);
                  }
                }
              )
            }
            else{
              this.alertService.error(res.msg, this.options);
            }
          }
        )
      
    }
    else{
      this.validateOtpLoginWithoutPswd = true;
    }
  }

  signinwithOTPPassword(){
    if(this.requestOTPLoginForm.valid){
      this.validaterequestOTPLoginForm = false;
      console.log("verifyOTP3")
        this.verifyOTP(this.requestOTPLoginForm.controls.mobileoremail.value, this.requestOTPLoginForm.controls.otp.value).then(
          respData => {
            let res:any = respData;
            if(res.status){
              let requestData = {
                "domain_name": ORG_DETAILS.DOMAIN_NAME,
                username: this.requestOTPLoginForm.controls.mobileoremail.value,
              }
              this.checkoutApiService.checkoutAPICall(ENDPOINTS.OTP_LOGIN, requestData).then(
                respData1 => {
                  let res1:any = respData1;
                  if(res1.success === 1){
                    let status_approved=true;
                    //status approved checks only for carience,Karupattiraja
                                if(this.config.title=='Carience'||this.config.title=='Karupattiraja')
                                {
                                  if(res1.user.status!="Approved")
                                  {
                                    status_approved=false;
                                  }
                    
                                }
                               if(status_approved)
                               {
                                console.log("data1414")
                                console.log("gstno"+res1.user.gst_number);
                                this.localStorageService.set('GSTNumber', res1.user.gst_number);
                                let reqData = {
                                  "domain_name": ORG_DETAILS.DOMAIN_NAME,
                                  "user_id": ORG_DETAILS.USER_ID,
                                  "extras": {
                                      "find": {
                                          "phone_number": this.requestOTPLoginForm.controls.mobileoremail.value
                                      }
                                  }
                                }
                                this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, reqData).then(
                                  respData2 => {
                                    console.log("data8")
                                    let res2:any = respData2;
                                    if(res2.success){
                                      if(res2.data.length > 0){
                                        console.log("data1515")
                                        this.screenType = "loggedIn";
                                        this.emailormobile = this.requestOTPLoginForm.controls.mobileoremail.value;
                                        let subscribed = false;
                                        if(res2.data[0].has_also_subscribed){
                                          subscribed = res2.data[0].has_also_subscribed;
                                        }
                                        this.customerLoginStorageService.setCustomerData({
                                          type:res2.data[0].customer_type,
                                          userid:res2.data[0].id,
                                          userName:res1.user.name ? res1.user.name : "",
                                          mobileNo: res2.data[0].phone_number ? res2.data[0].phone_number : "",
                                          email: res2.data[0].email ? res2.data[0].email : "",
                                          subscribed:subscribed,
                                          logintype:this.loggedinAs,
                                          company_address :  res2.data[0].company_address,
                                          company_name:  res2.data[0].company_name,
                                          gst_number: res2.data[0].gst_number
                                        })
                                        this.localStorageService.set('AiemaUserName', res2.data[0].name);
                                        this.customerLoginStorageService.setCustomerObject({
                                          customer_id:res2.data[0].id,
                                          customer_name:res1.user.name,
                                          phone_number:res2.data[0].phone_number,
                                          is_email_opt_in:subscribed
                                        })
                                        console.log(res1.user.name+'carience check username1')
                                        if(res1.user.name===undefined||res1.user.name==null||res1.user.name==''||res1.user.name=='undefined '||res1.user.name=='undefined'|| !res1.user.name){
                                          console.log(res1.user.name,'"goes to if')
                                          // this.setUserData(res2.data[0].id, res1.user.domain_name,this.initialRegistration.controls.mobile.value, res1.user.phoneNumber);
                                          console.log("111")
                                          this.setUserData(res2.data[0].id, res1.user.domain_name,this.initialRegistration.controls.mobile.value, res1.user.phoneNumber,res1.user.customer_group,res1.user.outlet_id);

                                        }
                                        else{
                                          console.log("goes to else")
                                          // this.setUserData(res2.data[0].id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber);
                                          console.log("111")
                                          this.setUserData(res2.data[0].id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber,res1.user.customer_group,res1.user.outlet_id);

                                        }
                                        //this.setUserData(res2.data[0].id, res1.user.domain_name, res1.user.name, res1.user.phoneNumber);
                                      }
                                    }
                                  })

                               }
                               else{
                                this.screenType = "login";
                                this.alertService.error("Your Account is in under verification.Once Verified,you can able to login", this.options);
                            
                               }
                    
                  }
                  else{
                    this.alertService.error(res1.message, this.options);
                  }
                }
              )
            }
            else{
              this.alertService.error(res.msg, this.options);
            }
          }
        )
      
    }
    else{
      this.validaterequestOTPLoginForm = true;
    }
  }

  requesttogetOTP(){
    this.requestOTPLoginForm.controls.mobileoremail.setValue(this.passwordForm.controls.mobileoremail.value);
    try {
      if(this.userIdType == 'mobile') {
        this.sendOTP(this.passwordForm.controls.mobileoremail.value).then(
          respData => {
            console.log("ttttttt")
            let res:any = respData;
            if(res){
              this.screenType = "requestOTPLoginForm";
              this.otpResendDuration = new Date().getTime() + 5*60*1000;
              this.resendMsg=this.requestOTPLoginForm.controls.mobileoremail.value;
              this.alertService.success('OTP has been sent to your Registered Email', this.options);
            }
          }
        )
      }
    } catch (err) {
      console.log("[ERROR]: Unable to send otp");
    }
    
  }

  signinwithPassword(){
    if(this.passwordForm.valid){
      this.validatePasswordForm = false;
      let requestData = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        username: this.passwordForm.controls.mobileoremail.value+"-"+ORG_DETAILS.DOMAIN_NAME,
        password: this.passwordForm.controls.password.value
      }
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.LOGIN, requestData).then(
        respData => {
          let res:any = respData;
           console.log("chk",res)
           console.log("data222")
          // console.log("gstno"+res.user.gst_number);
           this.localStorageService.set('GSTNumber', res.user!=null?res.user.gst_number:'');
          if(res.success === 1){
            let status_approved=true;
//status approved checks only for carience,Karupattiraja
            if(this.config.title=='Carience'|| this.config.title=='Karupattiraja')
            {
              if(res.user.status!="Approved")
              {
                status_approved=false;
              }

            }
           if(status_approved)
           {this.screenType = "loggedIn";
           this.emailormobile = this.passwordForm.controls.mobileoremail.value;
           let requestData1;
          
             requestData1 = {
               "domain_name": ORG_DETAILS.DOMAIN_NAME,
               "user_id": ORG_DETAILS.USER_ID,
               "extras": {
                   "find": {
                       "phone_number": this.passwordForm.controls.mobileoremail.value
                   }
               }
             }
           
           this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, requestData1).then(
             respData1 => {
              console.log("data9")
               let res1:any = respData1;
               if(res1.success){
                 let subscribed = false;
                 if(res1.data[0].has_also_subscribed){
                   subscribed = res1.data[0].has_also_subscribed;
                 }
                 this.customerLoginStorageService.setCustomerData({
                   type:res1.data[0].customer_type,
                   userid:res1.data[0].id,
                   userName:res.user.name ? res.user.name : "",
                   mobileNo: res1.data[0].phone_number ? res1.data[0].phone_number : "",
                   email: res1.data[0].email ? res1.data[0].email : "",
                   subscribed:subscribed,
                   logintype:this.loggedinAs,
                   company_address :  res1.data[0].company_address,
                   company_name:  res1.data[0].company_name,
                   gst_number: res1.data[0].gst_number
                 })
                 this.customerLoginStorageService.setCustomerObject({
                   customer_id:res1.data[0].id,
                   customer_name:res.user.name,
                   phone_number:res1.data[0].phone_number,
                   is_email_opt_in:subscribed
                 })
                 if(res.user.name==undefined||res.user.name==null||res.user.name==''|| res.user.name=='undefined'){
                  // this.setUserData(res1.data[0].id, res.user.domain_name,this.initialRegistration.controls.mobile.value,res.user.phoneNumber);
                  console.log("111")
                  this.setUserData(res1.data[0].id, res.user.domain_name,this.initialRegistration.controls.mobile.value,res.user.phoneNumber,res.user.customer_group,res1.user.outlet_id);
                 }
                 else{
                  // this.setUserData(res1.data[0].id, res.user.domain_name, res.user.name,res.user.phoneNumber);
                  console.log("111"+res1.data[0].id)
                  this.setUserData(res1.data[0].id, res.user.domain_name, res.user.name,res.user.phoneNumber,res.user.customer_group,res.user.outlet_id);
                 }
                
                 //this.setUserData(res1.data[0].id, res.user.domain_name, res.user.name,res.user.phoneNumber);
               }
             }
           )
           this.resetForms();
            
           }
           else{
            this.screenType = "login";
            this.alertService.error("Your Account is in under verification.Once Verified,you can able to login", this.options);
           }
            
          }
          else{
            this.alertService.error(res.message, this.options);
          }
        }
      )
    }
    else{
      this.validatePasswordForm = true;
    }
  }

  individualUserRegistration(){
    console.log("Button to sign up pressed: ")
    if(this.initialRegistration.controls.password.value === ""){
      this.validateInitialRegistration = true;
      this.initialRegistration.controls['password'].setErrors({'incorrect':true});
    }
    if(this.initialRegistration.valid){
      this.signup1 = true;
      this.validateInitialRegistration = false;
      if(this.individualRegistration.valid){
        this.validateIndividualRegistration = false;
        try{
          console.log("verifyOTP4")
          this.verifyOTP(this.initialRegistration.controls.mobile.value, this.initialRegistration.controls.otp.value).then(
            respData1 => {
              let res1:any = respData1;
              if(res1.status){
                this.localStorageService.set('UserName', this.initialRegistration.value.name);
                this.appService.userName = this.initialRegistration.value.name;
                let user = {
                  name: this.individualRegistration.controls.name.value,
                  email: this.initialRegistration.controls.email.value === "" || this.initialRegistration.controls.email.value === null || this.initialRegistration.controls.email.value === undefined ? this.individualRegistration.controls.email.value : this.initialRegistration.controls.email.value,
                  phone_number: this.initialRegistration.controls.mobile.value,
                  password: this.initialRegistration.controls.password.value,
                  customer_type: "individual customer"
                }
                let registrationData = {
                  domain_name: ORG_DETAILS.DOMAIN_NAME,
                  user_id: ORG_DETAILS.USER_ID,
                  payload: {
                    create_customer: user
                  }
                }
                // LOGIN FORM AUTOMATIC FILL
                this.passwordForm.controls.mobileoremail.setValue(this.initialRegistration.controls.mobile.value);
                this.passwordForm.controls.password.setValue(this.initialRegistration.controls.password.value);
                this.checkoutApiService.checkoutAPICall(ENDPOINTS.REGISTRATION, registrationData).then(
                  respData => {
                    let res:any = respData;
                    if(res.success){
                      this.alertService.success(res.message, this.options);
                      if(this.registrationOfferDetails.inidividual && this.offerAvailed){
                        this.individualCustomerRegistration = true;
                      }
                      console.log("Logged in with the new account!")
                      this.signinwithPassword();
                      this.signup1 = false;
                      // this.changeForm('initialstate')
                      this.hideMobile = true;
                      // FOR REGISTERD USER OFFER
                      if(this.registrationOfferDetails.inidividual && this.offerAvailed){
                        let registerData = res.data[0];
                        this.registrationOfferOrderService.setCustomerDetails({
                          userid : registerData.id,
                          userName : registerData.name,
                          type : registerData.customer_type,
                          subscribed : false,
                          mobileNo : registerData.phone_number
                        }).then(
                          respDataL => {
                            let respL:any = respDataL;
                            if(respDataL){
                              this.registrationOfferInitiationApiService.initiateSampleOrder().then(
                                respDataO =>{
                                  let resO:any = respDataO;
                                  this.offerOrderId = resO;
                                  if(resO){
                                    this.openMsgModel1()
                                    this.clearRegisterOffers();
                                  }
                                }
                              )
                            }
                          }
                        )
                        this.selectedAddress.customer_id = registerData.id;
                        if(this.config.title!="Carience" && this.config.title!="Deera"){
                          this.addAddress(this.selectedAddress);

                        }
                       
                      }
                    }
                    else{
                      this.alertService.error(res.message, this.options);
                    }
                })
              }
              else{
                this.signup1 = false;
                this.alertService.error(res1.msg, this.options);
              }
           }
          )
        } catch(err) {
          console.log("[ERROR]: ", err);
        }
      }
      else{
        this.signup1 = false;
        this.validateIndividualRegistration = true;
      }
    }
    else{
      this.validateInitialRegistration = true;
    }
  }

  businessUserRegistration(){
    if(this.initialRegistration.controls.password.value === ""){
      this.validateInitialRegistration = true;
      this.initialRegistration.controls['password'].setErrors({'incorrect':true});
    }
    if(this.initialRegistration.valid){
      this.signup2 = true;
      this.validateInitialRegistration = false;
      if(this.initialRegistration.controls.email.value==null||this.initialRegistration.controls.email.value==''|| this.initialRegistration.controls.email.value==undefined) {
        var business_email = this.businessRegistration.controls.email.value

      }
      else{
        this.businessRegistration.controls.email.setValue(this.initialRegistration.controls.email.value)

        var business_email = this.initialRegistration.controls.email.value

      }
      if(this.businessRegistration.valid){
        this.validateBusinessRegistration = false;
        console.log("verifyOTP5")
        this.verifyOTP(this.initialRegistration.controls.mobile.value, this.initialRegistration.controls.otp.value).then(
          respData => {
            let res:any = respData;
            
            if(res.status){
              let user = {
                name: this.businessRegistration.controls.name.value,
                email: business_email,
                phone_number: this.initialRegistration.controls.mobile.value,
                company_name: this.businessRegistration.controls.company.value,
                gst_number: this.businessRegistration.controls.gst_no.value,
                pan_number: this.businessRegistration.controls.pan_no.value,
                company_address: this.businessRegistration.controls.company_address.value,
                password: this.initialRegistration.controls.password.value,
                customer_type: "business customer"
              }
              let registrationData = {
                domain_name: ORG_DETAILS.DOMAIN_NAME,
                user_id: ORG_DETAILS.USER_ID,
                payload: {
                  create_customer: user
                }
              }
              // LOGIN FORM AUTOMATIC FILL
              this.passwordForm.controls.mobileoremail.setValue(this.initialRegistration.controls.mobile.value);
              this.passwordForm.controls.password.setValue(this.initialRegistration.controls.password.value);
              this.checkoutApiService.checkoutAPICall(ENDPOINTS.REGISTRATION, registrationData).then(
                respData => {
                  let res:any = respData;
                  if(res.success){
                    console.log("gstno"+res.data[0].gst_number);
                    this.localStorageService.set('GSTNumber', res.data[0].gst_number);
                    this.alertService.success(res.message, this.options);
                    // this.changeForm('initialstate');
                    this.signup2 = false;
                    if(this.registrationOfferDetails.business){
                      this.businessCustomerRegistration = true;
                    }
                    if(this.config.title=='Karupattiraja' || this.config.title=='Carience')
                    {
                     // this.getlat_long(this.businessRegistration.controls.zipcode.value)
                      var shipping_address = {
                        customer_id:res.data[0].id,
                        alias:"",
                        first_name:this.businessRegistration.controls.name.value,
                        last_name:"",
                        company_name:this.businessRegistration.controls.company.value,
                        entire_location:this.businessRegistration.controls.company_address.value,
                        address1:this.businessRegistration.controls.company_address.value,
                        landmark: '',
                        city: this.businessRegistration.controls.city.value,
                        state: this.businessRegistration.controls.state.value,
                        country: this.businessRegistration.controls.country.value,
                        country_code:"104",
                        zipcode: this.businessRegistration.controls.zipcode.value,
                        email: this.businessRegistration.controls.email.value,
                        phone_number: this.initialRegistration.controls.mobile.value,
                        is_preferred_address:true,
                        address_type:"",
                        longitude:this.longitude,
                        latitude:this.latitude,
                        gst_details : {
                          gst_location : "",
                          gst_flat_no : "",
                          gst_landmark : "",
                          gst_city : "",
                          gst_state : "",
                          gst_country : "",
                          gst_zipcode : "",
                          gst_mobile : "",
                          gst_email : "",
                          gst_full_name : "",
                          gst_latitude : "",
                          gst_longitude : "",
                          gst_same_address : false
                      }
                      }
this.addAddress(shipping_address);
                    }
                    console.log("insideeeeeeeeeeeeeeeeeeee")
                    this.signinwithPassword();
                    this.hideMobile = true;
                    // FOR REGISTERD USER OFFER
                    if(this.registrationOfferDetails.business && this.offerAvailed){
                      let registerData = res.data[0];
                    this.registrationOfferOrderService.setCustomerDetails({
                      userid : registerData.id,
                      userName : registerData.name,
                      type : registerData.customer_type,
                      subscribed : false,
                      mobileNo : registerData.phone_number
                    }).then(
                      respDataL => {
                        let respL:any = respDataL;
                        if(respDataL){
                          this.registrationOfferInitiationApiService.initiateSampleOrder().then(
                            respDataO =>{
                              let resO:any = respDataO;
                              this.offerOrderId = resO;
                              if(resO){
                                this.clearRegisterOffers();
                                this.openMsgModel1()
                              }
                            }
                          )
                        }
                      }
                    )
                    this.selectedAddress.customer_id = registerData.id;
                    this.addAddress(this.selectedAddress);
                    }
                  }
                  else{
                    this.signup2 = false;
                    this.alertService.error(res.message, this.options);
                  }
              })
            }
            else{
              this.signup2 = false;
              this.alertService.error(res.msg, this.options);
            }
          }
        )
      }
      else{
        this.signup2 = false;
        this.validateBusinessRegistration = true;
      }
    }
    else{
      this.validateInitialRegistration = true;
    }
  }

  forgotPassword(){
    if(this.forgotPasswordForm.valid){
      this.validateForgotPasswordForm = false;
      if(this.userIdType === "mobile"){
        console.log("verifyOTP6")
        this.verifyOTP(this.forgotMobile, this.forgotPasswordForm.controls.otp.value).then(
          respData => {
            let res:any = respData;
            if(res.status){
              let reqData = {
                "domain_name": ORG_DETAILS.DOMAIN_NAME,
                "user_id": ORG_DETAILS.USER_ID,
                "payload": {
                    "update": {
                        "password": this.forgotPasswordForm.controls.password.value
                    }
                },
                "extras": {
                    "find": {
                        "username": this.forgotMobile
                    }
                }
              }
              console.log("REQ DATA FOR FORGOT PASSWORD: ", reqData)
              this.checkoutApiService.checkoutAPICall(ENDPOINTS.RESET_PASSWORD, reqData).then(
                respData1 => {
                  let res1:any = respData1;
                  if(res1.success === 1){
                  let requestData1 = {
                      "domain_name": ORG_DETAILS.DOMAIN_NAME,
                      "user_id": ORG_DETAILS.USER_ID,
                      "extras": {
                          "find": {
                              "phone_number": this.forgotMobile
                          }
                      }
                    }
                    this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, requestData1).then(
                      respData2 => {
                        console.log("data10")
                        let subscribed = false;
                        let res2:any = respData2;
                        let status_approved=true;
                        //status approved checks only for carience and karupatti
                                    if(this.config.title=='Carience'|| this.config.title=='Karupattiraja')
                                    {
                                      if(res2.data[0].status!="Approved")
                                      {
                                        status_approved=false;
                                      }
                        
                                    }
                                   if(status_approved)
                                   {
                                    if(res2.data[0].has_also_subscribed){
                                      subscribed = res2.data[0].has_also_subscribed;
                                    }
                                    console.log("11")
                                    this.customerLoginStorageService.setCustomerData({
                                      type:res2.data[0].customer_type,
                                      userid:res2.data[0].id,
                                      // userName:res2.data[0].name!=null?res2.data[0].name:'New User',
                                      userName:res2.data[0].name,
                                      //(res2.data[0].first_name + " " + res2.data[0].last_name ? (res2.data[0].last_name ? res2.data[0].first_name + " " + res2.data[0].last_name : res2.data[0].first_name)  : ""),
                                      mobileNo: res2.data[0].phone_number ? res2.data[0].phone_number : "",
                                      email: res2.data[0].email ? res2.data[0].email : "",
                                      subscribed:subscribed,
                                      logintype:this.loggedinAs,
                                      company_address :  res2.data[0].company_address,
                                      company_name:  res2.data[0].company_name,
                                      gst_number: res2.data[0].gst_number
                                    })
                                    this.localStorageService.set('AiemaUserName', res2.data[0].name);
                                    this.customerLoginStorageService.setCustomerObject({
                                      customer_id:res2.data[0].id,
                                      customer_name:res2.data[0].name,
                                      phone_number:res2.data[0].phone_number,
                                      is_email_opt_in:subscribed
                                    })
                                    this.localStorageService.set('karupattirajaOutletId', res2.data[0].outlet_id);
                                    this.appService.outletId = res2.data[0].outlet_id;
                                    console.log("22")
                                    //if(res2.data[0].first_name==undefined||res2.data[0].first_name==null){
                                      // this.setUserData(res2.data[0].id, ""  , res2.data[0].name!=null?res2.data[0].name:"New User" , res2.data[0].phone_number,"",res2.data[0].outlet_id);
                                      this.setUserData(res2.data[0].id, ""  , res2.data[0].name, res2.data[0].phone_number,"",res2.data[0].outlet_id);

                                    //}
                                    // else{
                                    //   this.setUserData(res2.data[0].id, "", (res2.data[0].first_name + " " + res2.data[0].last_name ? (res2.data[0].last_name ? res2.data[0].first_name + " " + res2.data[0].last_name : res2.data[0].first_name)  : ""), res2.data[0].phoneNumber,"");
            
                                    // }
                                    
                            
                                      this.emailormobile = res2.data[0].phone_number;
                                    
                                    this.screenType = "loggedIn";
                                    this.resetForms();



                                   }
                                   else{
                                
                                    this.alertService.error("Your Account is in under verification.Once Verified,you can able to login", this.options);
                          
                                   }
                      
                      }
                    )
                  }
                }
              )
            }
            else{
              this.alertService.error(res.msg, this.options);
            }
          }
        )
      }
      else{
        console.log("verifyOTP1")
        this.verifyOTP(this.forgotPasswordForm.controls.mobileoremail.value, this.forgotPasswordForm.controls.otp.value).then(
          respData => {
            let res:any = respData;
            if(res.status){
              let reqData = {
                "domain_name": ORG_DETAILS.DOMAIN_NAME,
                "user_id": ORG_DETAILS.USER_ID,
                "payload": {
                    "update": {
                        "password": this.forgotPasswordForm.controls.password.value
                    }
                },
                "extras": {
                    "find": {
                        "username": this.forgotPasswordForm.controls.mobileoremail.value
                    }
                }
              }
              this.checkoutApiService.checkoutAPICall(ENDPOINTS.RESET_PASSWORD, reqData).then(
                respData1 => {
                  let res1:any = respData1;
                  if(res1.success === 1){
                    console.log("55555")
                  let requestData1 = {
                      "domain_name": ORG_DETAILS.DOMAIN_NAME,
                      "user_id": ORG_DETAILS.USER_ID,
                      "extras": {
                          "find": {
                              "email": this.forgotPasswordForm.controls.mobileoremail.value
                          }
                      }
                    }
                    this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALL_USERS, requestData1).then(
                      respData2 => {
                        console.log("data11")
                        let subscribed = false;
                        let res2:any = respData2;
                        let status_approved=true;
                        //status approved checks only for carience and karupatti
                                    if(this.config.title=='Carience'|| this.config.title=='Karupattiraja')
                                    {
                                      if(res2.data[0].status!="Approved")
                                      {
                                        status_approved=false;
                                      }
                        
                                    }
                                   if(status_approved)
                                   {
                                    if(res2.data[0].has_also_subscribed){
                                      subscribed = res2.data[0].has_also_subscribed;
                                    }
                                    console.log("33")
                                    this.customerLoginStorageService.setCustomerData({
                                      type:res2.data[0].customer_type,
                                      userid:res2.data[0].id,
                                      userName:res2.data[0].name!=null?res2.data[0].name:'New User',
                                      //(res2.data[0].first_name + " " + res2.data[0].last_name ? (res2.data[0].last_name ? res2.data[0].first_name + " " + res2.data[0].last_name : res2.data[0].first_name)  : ""),
                                      mobileNo: res2.data[0].phone_number ? res2.data[0].phone_number : "",
                                      email: res2.data[0].email ? res2.data[0].email : "",
                                      subscribed:subscribed,
                                      logintype:this.loggedinAs,
                                      company_address :  res2.data[0].company_address,
                                      company_name:  res2.data[0].company_name,
                                      gst_number: res2.data[0].gst_number
                                    })
                                    this.localStorageService.set('AiemaUserName', res2.data[0].name);
                                    this.customerLoginStorageService.setCustomerObject({
                                      customer_id:res2.data[0].id,
                                      customer_name:res2.data[0].name,
                                      phone_number:res2.data[0].phone_number,
                                      is_email_opt_in:subscribed
                                    })
                                    console.log("44")
                                    //(res2.data[0].first_name + " " + res2.data[0].last_name ? (res2.data[0].last_name ? res2.data[0].first_name + " " + res2.data[0].last_name : res2.data[0].first_name)  : "")
                                    this.setUserData(res2.data[0].id, "",res2.data[0].name!=null?res2.data[0].name:'New User' , res2.data[0].phoneNumber,"","");
                                
                                      this.emailormobile = res2.data[0].phone_number;
                                    
                                    this.screenType = "loggedIn";
                                    this.resetForms();


                                   }
                                   else{
                                    this.alertService.error("Your Account is in under verification.Once Verified,you can able to login", this.options);
                          
                                   }
                        
                      }
                    )
                  }
                }
              )
            }
            else{
              this.alertService.success(res.msg, this.options);
            }
          }
        )
      }
    }
    else{
      this.validateForgotPasswordForm = true;
    }
  }

  resendOTP1(){
    this.sendOTP(this.forgotMobile).then(
      respData => {
        console.log("ttttttt")
        let res:any = respData;
        if(res){
          this.otpResendDuration = new Date().getTime() + 5*60*1000;
          this.resendMsg=this.forgotMobile
          this.alertService.success('OTP has been sent to your Registered Email', this.options);
        }
      }
    )
  }

  requesttogetOTPpswd(){
    this.updateEmailForm2.controls.email.setValue(this.updateEmailForm3.controls.email.value);
    this.updateEmailForm2.controls.mobile.setValue(this.updateEmailForm3.controls.mobile.value);
    this.sendOTPToEmail(this.updateEmailForm3.controls.email.value).then(
      respData => {
        let res:any = respData;
        console.log(respData,"respDatanewuser")
        if(res){
          this.screenType = "updateEmailForm2"
          this.otpResendDuration = new Date().getTime() + 5*60*1000;
          this.resendMsg=this.updateEmailForm3.controls.email.value;
          this.alertService.success('OTP has been sent to your Registered Email', this.options);
        }
      }
    )
  }

  setUserData(userId, domainName, userName, phone_number,customer_group,outlet_id){
    console.log(userName,"userName123456789")
    this.localStorageService.set('UserId', userId);
    this.localStorageService.set('domainName', domainName);
    this.localStorageService.set('customerGroup', customer_group);
     console.log("outlet_idddddddddddddddd"+outlet_id)
    if(outlet_id===undefined || outlet_id===null){
      console.log("outlet_idddddddddddddddd"+outlet_id)
      this.localStorageService.set('karupattirajaOutletId', "");
      this.appService.outletId = "";
    } else {
      this.localStorageService.set('karupattirajaOutletId', outlet_id);
      this.appService.outletId = outlet_id;
      console.log("get id success",this.appService.outletId)
    }    
    this.appService.loggedin_user = true;
    this.appService.userLoggedIn = true;

       
    console.log("ffffffffffffffffffffffffff"+Number(this.localStorageService.get('cartId')))
    console.log("mmmmmmmmmmmmmmmmmmmmmmmmmm"+this.appService.cartProducts.results.length)

    // if(this.appService.cartProducts.results.length===0){
    //   let requestData1 = {
    //     "domain_name": ORG_DETAILS.DOMAIN_NAME,
    //     "user_id": ORG_DETAILS.USER_ID,
    //     "extras": {
    //       "find": {
    //         "userId":userId.toString()
    //       }
    //     }
    //   }
   
    // this.appService.postApiCall(requestData1,ENDPOINTS.GETALLCARTITEMS).subscribe(
    //   resp => {
    //     let res:any = resp;
    //     if(res.success){
    //       if(res.data.length > 0){
    //         if(res.data.products.length > 0){
    //           console.log("aadtaaaaaaaaaaaa")
    //           this.appService.cartProducts.results = res.data.products
    //           this.cartService.updateCartItem(
    //             this.appService.cartProducts.results
    //           );
    //         }
           
    //       }
    //     }
    //   }
    // )
    // }

    // if(this.appService.cartProducts.results.length>0){
    //   let requestData = {
    //     "domain_name": ORG_DETAILS.DOMAIN_NAME,
    //     "user_id": ORG_DETAILS.USER_ID,
    //     "payload": {
    //       "updatecustomer": {
    //         "userId":userId.toString()
    //       }
    //   },
    //     "extras": {
    //       "find": {
    //         // "id":Number(this.localStorageService.get('cartId'))
    //         "session_id": this.localStorageService.get("session_id")
    //       }
    //     }
    //   }
   
    // this.appService.postApiCall(requestData,ENDPOINTS.USERUPDATE).subscribe(
    //   resp => {
    //     let res:any = resp;
    //     if(res.success){
    //       let requestData1 = {
    //         "domain_name": ORG_DETAILS.DOMAIN_NAME,
    //         "user_id": ORG_DETAILS.USER_ID,
    //         "extras": {
    //           "find": {
    //             "userId":userId.toString()
    //           }
    //         }
    //       }
       
    //     this.appService.postApiCall(requestData1,ENDPOINTS.GETALLCARTITEMS).subscribe(
    //       resp => {
    //         let res:any = resp;
    //         if(res.success){
    //           if(res.data.length > 0){
    //             if(res.data.products.length > 0){
    //               console.log("aadtaaaaaaaaaaaa")
    //               this.appService.cartProducts.results = res.data.products
    //               this.cartService.updateCartItem(
    //                 this.appService.cartProducts.results
    //               );
    //             }
               
    //           }
    //         }
    //       }
    //     )
    //     }
    //   }
    // )
    // }


    if(configs.title=='BestGifts'){
     console.log("this.appService.cartProducts.results.length"+this.appService.cartProducts.results.length);
     if(this.appService.cartProducts.results.length>0){
      let requestBody = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {
            "id" : userId
          }
        }
      }
      // MAKE A SERVICE CALL HERE...
      this.appService.postApiCall(requestBody,ENDPOINTS.GET_CUSTOMER,).subscribe(
        resp => {
          if (resp) {
         
            this.customerType1= resp.result.data[0].customer_type;
            this.appService.cartProducts.results.forEach((element)=>{
              console.log("elementtttttttttttttt"+JSON.stringify(element))
              this.formData.push({
                "product_id": element.product_id,
                "product_cost": element.cost,
                "quantity": element.quantity
      } ) 
            })
            console.log("formDataaaaaaaaaaa"+JSON.stringify(this.formData))
            let tempArray = this.appService.cartProducts.results;
            let obj =
            {
              "domain_name": ORG_DETAILS.DOMAIN_NAME,
              "user_id": ORG_DETAILS.USER_ID,
              "extras": {
                "find": {
                  "customer_id": Number(userId),
                  "customer_type":this.customerType1,
                  "products": this.formData,
                }
              }
            }
            this.appService.postApiCall(obj, ENDPOINTS.STRUCTURED_GROUP_PROMOTION).subscribe(
              resp => {
                if (resp) {
                  if (resp.success === 1 && resp.status_code === 200) {
                    tempArray.forEach((item,i) => {
                      resp.result.data.discount_summary.forEach((item1,i) => {
                       if(item1.product_id === item.product_id){
                        item["discount_amount"] =
                        item1.discount_amount;
               
                        item[
                      "after_dicount_price"
                    ] = item1.after_dicount_price;
                    item[
                      "per_quantity_amount"
                    ] = item1.per_quantity_amount;
                       }
                      })
                    })
                    this.appService.cartProducts.results = tempArray;
                    
          
                    this.cartService.updateCartItem(this.appService.cartProducts.results);
                    // console.log("Updating cart: ", this.appService.cartProducts.results)
                  } else if (resp.success === 0) {
                    if (resp.message) {
                      //this.alertService.error(resp.message, this.options);
                    }
                  } else if (resp.message && resp.status_code !== 200) {
                    //this.alertService.error(resp.message, this.options);
                  } else {
                    //this.alertService.error('Something bad happened. Please try again!', this.options);
                  }
                }
              },
              (err) => {
                if (err.error.statusCode === 403) {
                  //this.alertService.error('Session Time Out! Please login Again', this.options)
                } else if (err.error.error.message) {
                  //this.alertService.error(err.error.error.message, this.options)
                } else {
                  //this.alertService.error('Something bad happened. Please try again!', this.options);
                }
              }
            );
          }
          else{
          }
         
        })

     }
    }

    console.log("55")
    if(userName === "undefined undefined" || userName == undefined || !userName || userName === ""){
      console.log(userName,phone_number,"username and password")
      
      this.localStorageService.set('UserName',  phone_number =='' || phone_number == undefined ? 'New User' : phone_number);
      this.appService.userName = phone_number =='' || phone_number == undefined ? 'New User' : phone_number;
    }
    else{
      this.localStorageService.set('UserName', userName);
      this.appService.userName = userName;
    }
    console.log(this.registrationOfferDetails.available, this.individualCustomerRegistration, this.businessCustomerRegistration, this.offerAvailed , !this.offerOrderId)   
    if(this.productId && configs.title=='Aiema'){
      this.router.navigate(['/company-details1',this.productId,], { queryParams: { fromLogin:'fromLogin' },relativeTo:this.route,  skipLocationChange: false })
    } 
    if(configs.title=='Cle' || configs.title=='LinenRaj' ){
      // this.router.navigate(['/cart'], { queryParams: { fromLogin:'fromLogin' },relativeTo:this.route,  skipLocationChange: false })
      // this.router.navigate(['/'], {relativeTo:this.route,  skipLocationChange: false })
      // this.router.navigateByUrl(this.backToUrl);
      this.router.navigate(['/home-two'], { queryParams: { },relativeTo:this.route,  skipLocationChange: false })

    } 
    else if(this.productId && configs.title=='Beaubelle'){
      this.router.navigate(['/product-details',this.productId,], { queryParams: { fromLogin:'fromLogin' },relativeTo:this.route,  skipLocationChange: false })
    }
    else if(this.productId && configs.title=='Vimpro'){
      this.router.navigate(['/product-details',this.productId,], { queryParams: { fromLogin:'fromLogin' },relativeTo:this.route,  skipLocationChange: false })
    }
    
    
    
    else  if(!this.offerAvailed && this.offerOrderId === ""){
      this.router.navigateByUrl(this.backToUrl);
    }
    
  }

  availOffer(){
    let htmlcheckBox = document.getElementById('availOffer') as HTMLInputElement
    this.offerAvailed = htmlcheckBox.checked;
    if(this.offerAvailed){
      this.openMsgModel();
    }
    else{
      this.shippingForm.reset();
      this.selectedAddress = null;
    }
  }

  backtoHome(){
    document.getElementById('cancelBtn1').click();
  }

  openMsgModel(){
    document.getElementById('launch-address').click();
  }

  openMsgModel1(){
    document.getElementById('launch-surprise').click();
  }

  closeModel1(){
    document.getElementById('cancelBtn2').click();
    this.router.navigateByUrl(this.backToUrl);
  }

  clearRegisterOffers(){
    this.registrationOfferDetails = {
      available:false,
      inidividual:false,
      business:false,
      product_ids:[]
    }
    this.validateShippingForm  = false;
    this.location_bloked  = false;
    this.is_deliverable  = true;
    this.pickupLatitude = undefined ;
    this.pickupLongitude = undefined  ;
    this.offerAvailed = false;
    this.latitude;
    this.longitude;
    this.zoom;
    this.geocoder;
    this.enabledistanceValidation = false;
    this.distance = 0;
    this.validAddress  = "";
    this.selectedAddress  = null;
      this.optionss = {
      types: [],
      componentRestrictions: { country: 'IN' },
    };
  }

  dataChange(event) {
    console.log("fffffffffffffff")
    this.IECNo = false
    this.ValidIECNo = false
    this.IncorrectIECNo = false
  }

  getOtpType(type){
    this.otpType = type
    this.sendOTP(type);
  }


}
