import { AgmCoreModule } from "@agm/core";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AlertsModule } from "src/app/Components/alerts/alerts.module";
import { LoginNewComponent } from "./login-new.component";
import { LoginNewRoutingModule } from "./login-new.routing.module";
import { RegisterOfferCalculationsService } from "./register-offer-calculations.service";
import { RegistrationOfferApiService } from "./registration-offer-api.service";
import { RegistrationOfferInitiationApiService } from "./registration-offer-initiation-api.service";
import { RegistrationOfferInitiationService } from "./registration-offer-initiation.service";
import { RegistrationOfferOrderService } from "./registration-offer-order.service";
import { RegistrationOfferSettingsService } from "./registration-offer-settings.service";

@NgModule ({
    declarations: [
        LoginNewComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyB0Pd6TkW7Ip2rIDZsXgPhyfLprulvDn7U',
        }),
        GooglePlaceModule,
        AlertsModule,
        LoginNewRoutingModule
    ],
    providers: [
        RegistrationOfferApiService,
        RegisterOfferCalculationsService,
        RegistrationOfferInitiationApiService,
        RegistrationOfferInitiationService,
        RegistrationOfferOrderService,
        RegistrationOfferSettingsService
    ]
})

export class LoginNewModule { };