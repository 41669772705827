import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginNewComponent } from "./login-new.component";

const routes: Routes = [
    { path: '', component: LoginNewComponent }
]

@NgModule ({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class LoginNewRoutingModule { };