<head>
    <meta http-equiv="Cache-Control" content="no-store">
    <meta http-equiv="Pragma" content="no-cache">
    <meta http-equiv="Expires" content="0">
 </head>


<section>
    <div>
        
        <div class="">
            <!-- <img src="https://ecbee-labes-images1.s3.ap-south-1.amazonaws.com/Cle/StoreGallery/CLE+BANNER_+1.jpg" width="100%"> -->
            <img src="../../../../assets/images/cle/CLE-Home1121212.png" width="100%">
            <div class="checkout-alerts">
                <app-alerts *ngIf="checkoutAlerts"></app-alerts>
            </div>
            <div class="row ban_content_cle" style="justify-content: center;">
                <div class="">
                    <div class="row end_look" style="justify-content: center;margin-right: 0px;">
                        <div class="logn-part" style="justify-content: center;">
                            <div class="customer-container Customer_content" style="background-color: rgba(0, 0, 0, 0.629);">
                                <!-- INITIAL FORM -->
                                 <div>
                                    <img src="../../../../assets/images/cle/cccs.png" width="100%">
                                 </div>
                                <div *ngIf="screenType === 'initial'">
                                    <form class="checkout-forms" [formGroup]= "initialForm">
                                        <div *ngIf="hideMobile">
                                            <div class="form-group">
                                                <label>
                                                    <div style="display: flex;justify-content: center;">
                                                    <input type="text" class="form-control" formControlName="email" [class.is-invalid] = "validateInitialForm && f.email.errors" placeholder="IE Code" style="width: 58%;" (input)="dataChange($event)">
                                                    <span class="checkout-label left_cle_event1" style="color: red;margin-top: -3px;">IE Code*</span>
                                                </div>
                                                    <!-- <div class="invalid-feedback" *ngIf="f.email.errors"> -->
                                                        <div *ngIf="IECNo==true" style="background-color: #ffffff00;width: 65%;margin-left: 21%;;color: #da0000;">Please enter a IE Code.</div>
                                                        <div *ngIf="ValidIECNo==true" style="background-color: #ffffff00;width: 65%;margin-left: 21%;;color: #da0000;">Please enter a Valid IE Code.</div>
                                                        <div *ngIf="IncorrectIECNo==true" style="background-color: #ffffff00;width: 65%;margin-left: 21%;;color: #da0000;">Incorrect IE Code.</div>

                                                        <!-- <div *ngIf="f.email.errors.incorrect">Please enter a valid MemberId/IEC Number.</div> -->
                                                    <!-- </div>  -->
                                                </label>
                                            </div>
                                        </div>
                                        <div *ngIf="!hideMobile">
                                            <div class="form-group">
                                                <div class="row" style="justify-content: center;">
                                                    <div class="col-sm-9" style="padding-right: 0px !important; margin-left: -35px;">
                                                        <label>
                                                            <div style="display: flex;justify-content: center;">
                                                            <input type="text" class="form-control" formControlName="email" [class.is-invalid] = "validateInitialForm && f.email.errors" placeholder="MemberId/IE Code" readonly style="width: 58%;">
                                                            <span class="checkout-label left_cle_event1" style="color: red;padding-left: 5px;padding-right: 5px;left: 15% !important;">IE Code*</span>
                                                            </div>
                                                            <div class="invalid-feedback" *ngIf="f.email.errors">
                                                                <div *ngIf="f.email.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter IE Code.</div>
                                                                <div *ngIf="f.email.errors.incorrect">Please enter a valid IE Code.</div>
                                                            </div> 
                                                        </label> 
                                                    </div>
                                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 26px; position: absolute;right: 18%;    margin-left: -35px;">
                                                        <label class="mobile-options">
                                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;margin-left:25px;background-color: #dfcdcda6;width: 65%;width: 45%;margin-left: -40%;" (click)="changeForm('initialstate')">Change?</a>
                                                        </label>                                   
                                                    </div> 
                                                </div>                 
                                            </div>
                                            <div class="form-group">
                                                <label>
                                                    <div style="display: flex;justify-content: center;">
                                                    <input type="text" class="form-control" formControlName="mobile" [class.is-invalid] = "validateInitialForm && f.mobile.errors" placeholder="Mobile No" style="width: 58%;">
                                                    <span class="checkout-label">Mobile No*</span>
                                                    </div>
                                                    <div class="invalid-feedback" *ngIf="f.mobile.errors">
                                                        <div *ngIf="f.mobile.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter Mobile Number.</div>
                                                        <div *ngIf="f.mobile.errors.incorrect" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter a valid Mobile Number.</div>
                                                        <div *ngIf="f.mobile.errors.exists" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">This Mobile Number already registered with us. Try to Login!</div>
                                                    </div> 
                                                </label>
                                            </div>
                                            <button  *ngIf="config.title=='Vimpro'" style="background-color: #2554c7!important;"  class="btn btn-block btn-primary btn-primary1" (click)="verifyUserDetails()" [disabled]="continue1"><P *ngIf="continue1" class="spinner-border spinner-border-sm"></P> CONTINUE</button>
                                            <div class="center_btn">
                                            <button  *ngIf="config.title!='Vimpro'"    class="btn btn-block btn-primary btn-primary1 continue_btn_login" (click)="verifyUserDetails()" [disabled]="continue1"><P *ngIf="continue1" class="spinner-border spinner-border-sm"></P> CONTINUE</button>
                                        </div>
                                        </div>
                                        <div class="center_btn">
                                        <!-- <button *ngIf="hideMobile && config.title!='Vimpro'" class="btn btn-block btn-primary btn-primary1 continue_btn_login" (click)="verifyUser()" [disabled]="continue2"><P *ngIf="continue2" class="spinner-border spinner-border-sm"></P> CONTINUE3</button> -->
                                        <button *ngIf="hideMobile && config.title!='Vimpro'" class="btn btn-block btn-primary btn-primary1 continue_btn_login" (click)="verifyUser()"> CONTINUE</button>

                                    </div>
                                        <button *ngIf="hideMobile && config.title=='Vimpro'" style="background-color: #2554c7!important;" class="btn btn-block btn-primary btn-primary1" (click)="verifyUser()" [disabled]="continue2"><P *ngIf="continue2" class="spinner-border spinner-border-sm"></P> CONTINUE</button>
                                    </form>
                                </div>
                                <!-- LOGIN FORM -->
                                <div *ngIf="screenType === 'login'">
                                    <form class="checkout-forms" [formGroup]= "passwordForm">
                                        <div class="form-group">
                                            <div class="row" style="justify-content: center;">
                                                <div class="col-sm-9" style="padding-right: 0px !important;">
                                                    <label>
                                                        <div style="display: flex;justify-content: center;margin-left: -10%;">
                                                        <input type="text" class="form-control" formControlName="mobileoremail" [class.is-invalid] = "validatePasswordForm && f1.mobileoremail.errors" placeholder="MemberId/IE Code" readonly style="width: 59%;">
                                                        <span class="checkout-label left_cle_event1" style="color: red !important;padding-left: 5px;padding-right: 5px; left: 14% !important;">IE Code*</span>
                                                    </div>
                                                    </label> 
                                                </div>
                                                <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 26px; position: absolute;right: 18%;    margin-left: -35px;">
                                                    <label class="mobile-options">
                                                        <a class="login-link-color" style="font-weight: 500; cursor: pointer;margin-left:25px;color: white !important;width: 65%;width: 45%;margin-left: 10%;margin-top: -5%;" (click)="changeForm('initialstate')">Change?</a>
                                                    </label>                                     
                                                </div> 
                                            </div>                 
                                        </div>
                                        <div class="form-group mob-second" style="margin-top: 10px;">
                                            <div class="row" style="justify-content: center;">
                                                <div class="col-sm-10" style="padding-right: 0px !important;margin-left: -35px;">
                                                    <label>
                                                        <div style="display: flex;justify-content: center;">
                                                        <input [type]="showLoginpaswd ? 'text' : 'password'" class="form-control" formControlName="password" [class.is-invalid] = "validatePasswordForm && f1.password.errors" placeholder="Password" style="width: 58%;">
                                                        <span class="checkout-label left_cle_event1" style="padding-left: 5px;padding-right: 5px;left: 23% !important;margin-top: -3px;color: red;">Password*</span>
                                                        </div>
                                                        <div class="invalid-feedback" *ngIf="f1.password.errors" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">
                                                            <div *ngIf="f1.password.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter the password</div>
                                                        </div>
                                                    </label> 
                                                </div>
                                                <div class="col-sm-2 fcolor1" style="padding-left: 0px !important; bottom: 33% !important; margin-left: -35px;">
                                                    <label class="fcolor mobile-options" >
                                                        <i (click)="showLoginPassword()" class="mob-eye " [class]="showLoginpaswd ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                                                    </label>                                   
                                                </div> 
                                            </div>                 
                                        </div>
                                    </form>
                                    <div class="d-flex justify-content-end mt-1" style="margin-left: 25px;width: 92%;">
                                        <a class="forgot-link mob-fgt" style="color: white !important;"  (click)="changeForm('forgotPassword')"> Forgot password?</a>
                                    </div>
                                    <button  *ngIf="config.title=='Vimpro'" style="background-color: #2554c7!important;" class="btn btn-block btn-primary btn-primary1" (click)="signinwithPassword()">SIGN IN</button>
                                    <div style="display: flex;justify-content: center;width: 97%;" *ngIf="config.title!='Vimpro'">
                                    <button  class="btn btn-block btn-primary btn-primary1" style="width: 42%;background-color: #009900;" (click)="signinwithPassword()">SIGN IN</button>
                                    </div>
                                    <!-- <div class="text-center" style="padding: 5px 0px;">
                                        <p class="fcolor">OR</p>
                                    </div>
                                    <button class="btn btn-block btn-primary btn-secondary1" (click)="requesttogetOTP()">REQUEST OTP</button> -->
                                </div>
                                <!-- OTP LOGIN -->
                                <div *ngIf="screenType === 'otploginwithoutpswd'">
                                    <form class="checkout-forms" [formGroup]= "otpLoginWithoutPswd">
                                        <div class="form-group">
                                            <div class="row" style="justify-content: center;">
                                                <div class="col-sm-9" style="padding-right: 0px !important;    margin-left: 6%;">
                                                    <label style="display: flex;justify-content: center;">
                                                        <input type="text" class="form-control" formControlName="mobileoremail" [class.is-invalid] = "validateOtpLoginWithoutPswd && f6.mobileoremail.errors" placeholder="MemberId/IE Code" readonly style="width: 73%;">
                                                        <span class="checkout-label">IE Code*</span>
                                                    </label> 
                                                </div>
                                                <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px; position: absolute;right: 8%;    margin-left: -35px;">
                                                    <label class="mobile-options">
                                                        <a class="login-link-color" style="font-weight: 500; cursor: pointer;margin-left:25px;background-color: #dfcdcda6;width: 65%;width: 45%;margin-left: -20%;" (click)="changeForm('initialstate')">Change?</a>
                                                    </label>                                     
                                                </div> 
                                            </div>                 
                                        </div>
                                        <div class="form-group mob-second">
                                            <div class="row" style="justify-content: center;">
                                                <div class="col-sm-9" style="padding-right: 0px !important;    margin-left: -65px;">
                                                    <label >
                                                        <div style="display: flex;justify-content: center;">
                                                        <input type="text" class="form-control" formControlName="otp" [class.is-invalid] = "validateOtpLoginWithoutPswd && f6.otp.errors" placeholder="OTP" style="width: 73%;">
                                                        <span class="checkout-label left_cle_event" style="color:red;padding-left: 5px;padding-right: 5px;">OTP*</span>
                                                        </div>
                                                        <div class="invalid-feedback" *ngIf="f6.otp.errors">
                                                            <div *ngIf="f6.otp.errors.required">Please enter OTP.</div>
                                                        </div> 
                                                    </label>
                                                </div>
                                                <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px; position: absolute;right: 10%;    margin-left: -35px;">
                                                    <label class="mobile-options" style="display: flex;justify-content: center;">
                                                        <a class="login-link-color" style="font-weight: 500; cursor: pointer;margin-left:25px;background-color: #dfcdcda6;width: 65%;margin-left: 17%;;" (click)="resendCode('otplogin2')">Resend?</a>
                                                    </label>                                    
                                                </div>
                                            </div>
                                            <small *ngIf="userIdType === 'mobile'" class="help-text" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter the OTP that has been sent to your Registered Mobile</small>
                                            <small *ngIf="userIdType === 'email'" class="help-text" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter the OTP that has been sent to your Registered Email</small>
                                        </div>
                                        <div class="form-group desk">
                                            <div class="row" style="justify-content: center;">
                                                <div class="col-sm-10" style="padding-right: 0px !important;    margin-left: -65px;">
                                                    <label >
                                                        <div style="display: flex;justify-content: center;">
                                                        <input [type]="showotpwithoutpswd ? 'text' : 'password'" class="form-control" formControlName="password" [class.is-invalid] = "validateOtpLoginWithoutPswd && f6.password.errors" placeholder="Set Password" style="width: 65%;">
                                                        <span class="checkout-label left_cle_event" style="background-color: white;padding-left: 5px;padding-right: 5px;">Set Password</span>
                                                        </div>
                                                        <div class="invalid-feedback" *ngIf="f6.password.errors">
                                                            <div *ngIf="f1.password.errors.required">Please enter the password</div>
                                                            <div *ngIf="f6.password.errors.maxlength && !f5.password.errors.minlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Password should not be more than 20 characters</div>
                                                            <div *ngIf="f6.password.errors.minlength && !f5.password.errors.maxlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Password should not be less than 6 characters</div>
                                                        </div>
                                                    </label> 
                                                </div>
                                                <div class="col-sm-2 fcolor2" style="padding-left: 0px !important; margin-top: 31px;">
                                                    <label style="display: flex;justify-content: center;">
                                                        <i (click)="showOTPWithoutPswd()" class="mob-eye fcolor" [class]="showotpwithoutpswd ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                                                    </label>                                   
                                                </div> 
                                            </div>                 
                                        </div>
                                        <div class="form-group div-only-mobile">
                                            <div class="row" style="justify-content: center;">
                                                <div class="col-sm-10" style="padding-right: 0px !important;    margin-left: -35px;">
                                                    <label style="width: 90%;">
                                                        <input [type]="showotpwithoutpswd ? 'text' : 'password'" class="form-control" formControlName="password" [class.is-invalid] = "validateOtpLoginWithoutPswd && f6.password.errors" placeholder="Set Password" style="width: 58%;">
                                                        <span class="checkout-label">Set Password</span>
                                                        <div class="invalid-feedback" *ngIf="f6.password.errors">
                                                            <div *ngIf="f1.password.errors.required">Please enter the password</div>
                                                            <div *ngIf="f6.password.errors.maxlength && !f5.password.errors.minlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Password should not be more than 20 characters</div>
                                                            <div *ngIf="f6.password.errors.minlength && !f5.password.errors.maxlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Password should not be less than 6 characters</div>
                                                        </div>
                                                    </label> 
                                                    <i (click)="showOTPWithoutPswd()" class="mob-eye fcolor1" [class]="showotpwithoutpswd ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true" style="margin-left: 0px !important;    margin-left: -35px;"></i>

                                                </div>
                                                <!-- <div class="col-sm-2" style="padding-left: 0px !important; margin-top: 31px;">
                                                    <label>
                                                    </label>                                   
                                                </div>  -->
                                            </div>                 
                                        </div>
                                    </form>
                                    <div style="display: flex;justify-content: center;">
                                    <button style="width: 50%;background-color: #009900;" class="btn btn-block btn-primary btn-primary1" (click)="signinwithOTPWTPassword()">SIGN IN</button>
                                </div>
                                </div>
                                <!-- OTP LOGIN FORM WITHOUT PASSWORD -->
                                <div *ngIf="screenType === 'requestOTPLoginForm'">
                                    <form class="checkout-forms" [formGroup]= "requestOTPLoginForm">
                                        <div class="form-group">
                                            <div class="row" style="justify-content: center;">
                                                <div class="col-sm-9" style="padding-right: 0px !important;    margin-left: 6%;">
                                                    <label style="display: flex;justify-content: center;">
                                                        <input type="text" class="form-control" formControlName="mobileoremail" [class.is-invalid] = "validaterequestOTPLoginForm && f7.mobileoremail.errors" placeholder="MemberId/IE Code" readonly style="width: 73%;">
                                                        <span class="checkout-label left_cle_event1" style="color: red;padding-left: 5px;padding-right: 5px;">IE Code*</span>
                                                    </label> 
                                                </div>
                                                <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px; position: absolute;right: 8%;">
                                                    <label class="mobile-options">
                                                        <a class="login-link-color" style="font-weight: 500; cursor: pointer;margin-left:25px;background-color: #dfcdcda6;width: 65%;width: 45%;margin-left: -20%;" (click)="changeForm('initialstate')">Change?</a>
                                                    </label>                                     
                                                </div> 
                                            </div>                 
                                        </div>
                                        <div class="form-group mob-second">
                                            <div class="row" style="justify-content: center;">
                                                <div class="col-sm-9" style="padding-right: 0px !important;    margin-left: -65px;">
                                                    <label>
                                                        <div style="display: flex;justify-content: center;">
                                                        <input type="text" class="form-control" formControlName="otp" [class.is-invalid] = "validaterequestOTPLoginForm && f7.otp.errors" placeholder="OTP" style="width: 73%;">
                                                        <span class="checkout-label left_cle_event" style="color:red;padding-left: 5px;padding-right: 5px;">OTP*</span>
                                                        </div>
                                                        <div class="invalid-feedback" *ngIf="f7.otp.errors">
                                                            <div *ngIf="f7.otp.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter OTP.</div>
                                                        </div> 
                                                    </label>
                                                </div>
                                                <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px; position: absolute;right: 8%;    margin-left: -65px;">
                                                    <label class="mobile-options">
                                                        <a class="login-link-color" style="font-weight: 500; cursor: pointer;margin-left:25px;background-color: #dfcdcda6;width: 65%;margin-left: 17%;;" (click)="resendCode('otplogin2')">Resend?</a>
                                                    </label>                                   
                                                </div>
                                            </div>
                                            <small *ngIf="userIdType === 'mobile'" class="help-text" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter the OTP that has been sent to your Registered Mobile</small>
                                            <small *ngIf="userIdType === 'email'" class="help-text" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter the OTP that has been sent to your Registered Email</small>
                                        </div>
                                    </form>
                                    <div style="display: flex;justify-content: center;">
                                    <button style="width: 50%;background-color: #009900;" class="btn btn-block btn-primary btn-primary1" (click)="signinwithOTPPassword()">SIGN IN</button>
                                </div>
                                </div>
                                <!-- FORGOT PASSWORD -->
                                <div *ngIf="screenType === 'forgotPassword'">
                                    <form class="checkout-forms" [formGroup]= "forgotPasswordForm">
                                        <div class="form-group">
                                            <div class="row" style="justify-content: center;">
                                                <div class="col-sm-9" style="padding-right: 0px !important;    margin-left: 6%;">
                                                    <label style="display: flex;">
                                                        <input type="text" class="form-control" formControlName="mobileoremail" [class.is-invalid] = "validateForgotPasswordForm && f5.mobileoremail.errors" placeholder="MemberId/IE Code" readonly style="width: 73%;">
                                                        <span class="checkout-label left_cle_event1" style="left: 2% !important;color: red">IE Code*</span>
                                                    </label> 
                                                </div>
                                                <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 26px; position: absolute;right: 11%;    margin-left: -35px;">
                                                    <label class="mobile-options">
                                                        <a class="login-link-color" style="font-weight: 500; cursor: pointer;margin-left:25px;color: white !important; width: 65%;width: 45%;margin-left: -20%;" (click)="changeForm('initialstate')">Change?</a>
                                                    </label>                                     
                                                </div> 
                                            </div>                 
                                        </div>
                                        <div class="form-group mob-second" style="margin-top: 10px;">
                                            <div class="row" style="justify-content: center;">
                                                <div class="col-sm-9" style="padding-right: 0px !important;    margin-left: -65px;">
                                                    <label>
                                                        <div style="display: flex;justify-content: center;">
                                                        <input type="text" class="form-control" formControlName="otp" [class.is-invalid] = "validateForgotPasswordForm && f5.otp.errors" placeholder="OTP" style="width: 73%;">
                                                        <span class="checkout-label left_cle_event" style="color:red;padding-left: 5px;padding-right: 5px;left:16% !important;margin-top: -2px;">OTP*</span>
                                                        </div>
                                                        <div class="invalid-feedback" *ngIf="f5.otp.errors">
                                                            <div *ngIf="f5.otp.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter OTP.</div>
                                                        </div> 
                                                    </label>
                                                </div>
                                                <div class="" style="padding-left: 0px !important; margin-top: 25px; position: absolute;right: 36%;">
                                                    <label class="mobile-options" style="display: flex;">
                                                        <a class="login-link-color" style="font-weight: 500; cursor: pointer;margin-left:25px;color: white !important;" (click)="resendCode('otplogin2')">Resend?</a>
                                                    </label>                                     
                                                </div>
                                            </div>
                                            <small *ngIf="userIdType === 'mobile'" class="help-text" style="background-color: #ffffff00;width: 65%;margin-left: 16%;font-size: 12px;color: #da0000;">Please enter the OTP that has been sent to your Registered Mobile</small>
                                            <small *ngIf="userIdType === 'email'" class="help-text" style="background-color: #ffffff00;width: 65%;margin-left: 16%;font-size: 12px;color: #da0000;">Please enter the OTP that has been sent to your Registered Email</small>
                                        </div>
                                        <div class="form-group">
                                            <div class="row" style="justify-content: center;">
                                                <div class="col-sm-10" style="padding-right: 0px !important;    margin-left: -65px;">
                                                    <label>
                                                        <div style="display: flex;justify-content: center;">
                                                        <input [type]="showForgotPswd ? 'text' : 'password'" class="form-control" formControlName="password" [class.is-invalid] = "validateForgotPasswordForm && f5.password.errors" placeholder="Set Password" style="width: 65%;">
                                                        <span class="checkout-label left_cle_event" style="color:red;padding-left: 5px;padding-right: 5px;left: 20% !important;margin-top: -2px;">Set Password*</span>
                                                        </div>
                                                        <div class="invalid-feedback" *ngIf="f5.password.errors">
                                                            <div *ngIf="f5.password.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter the password</div>
                                                            <div *ngIf="f5.password.errors.maxlength && !f5.password.errors.minlength" style="background-color: #ffffff00;width: 65%;margin-left: 16%;font-size: 12px;color: #da0000;">Password should not be more than 20 characters</div>
                                                            <div *ngIf="f5.password.errors.minlength && !f5.password.errors.maxlength" style="background-color: #ffffff00;width: 65%;margin-left: 16%;font-size: 12px;color: #da0000;">Password should not be less than 6 characters</div>
                                                            <div *ngIf="f5.password.errors.required && !f5.password.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 16%;font-size: 12px;color: #da0000;">Please set the password for your account</div>
                                                        </div>
                                                    </label> 
                                                </div>
                                                <div class="col-sm-2 fcolor2" style="padding-left: 0px !important; margin-top: 31px;left: 56% !important;bottom: 22% !important;">
                                                    <label class="mobile-options">
                                                        <i (click)="showForgotPassword()" class="mob-eye fcolor" [class]="showForgotPswd ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                                                    </label>                                   
                                                </div> 
                                            </div>                 
                                        </div>
                                    </form>
                                    <div style="display: flex;justify-content: center;">
                                    <button  style="width: 50%;background-color: #009900;" class="btn btn-block btn-primary btn-primary1" (click)="forgotPassword()">SIGN IN</button>
                                </div>
                                </div>
                                <!-- INITIAL REGISTRATION FORM -->
                                <div *ngIf="screenType === 'initialRegistration'">
                                    <form class="checkout-forms" [formGroup]= "initialRegistration">
                                        <div class="form-group">
                                            <div class="row" style="justify-content: center;">
                                                <div class="col-sm-9" style="padding-right: 0px !important;    margin-left: 6%;">
                                                    <label>
                                                        <input type="text" class="form-control" formControlName="mobile" [class.is-invalid] = "validateInitialRegistration && f2.mobile.errors" placeholder="Mobile No" readonly style="width: 73%;">
                                                        <span class="checkout-label left_cle_event1" style="left: 2% !important;color: red">IE Code*</span>
                                                    </label> 
                                                </div>
                                                <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 26px; position: absolute;right: 11%;    margin-left: -35px;">
                                                    <label class="mobile-options" style="display: flex;">
                                                        <a class="login-link-color" style="font-weight: 500; cursor: pointer;margin-left:25px;color: white !important;width: 65%;width: 45%;margin-left: -20%;" (click)="changeForm('initialstate')">Change?</a>
                                                    </label>                                     
                                                </div> 
                                            </div>                 
                                        </div>
                                        <div class="form-group" *ngIf="selectType==true" style="margin-top: 5%;">
                                            <a style="color: white!important;margin-left: 17%;">*Please Select To Sent OTP</a>
                                            <div class="row" style="justify-content: center;">
                                                <div class="col-sm-3" style="padding-right: 0px !important;">
                                                    <input type="radio" style="height: 35px;width: 20px;" id="mobile" name="fav_language" value="Mobile" (change)="getOtpType($event.target.value)">
                                                     <span for="mobile" style="color: white;">Mobile</span>                                               
                                                     </div> 
                                                <div class="col-sm-3" style="padding-right: 0px !important;">
                                                    <input type="radio" style="height: 35px;width: 20px;" id="email" name="fav_language" value="Email" (change)="getOtpType($event.target.value)">
                                                    <span for="email" style="color: white;">Email</span>
                                                </div>                
                                        </div>
                                        </div>
                                        <div *ngIf="userIdType === 'email'">
                                            <div class="form-group">
                                                <div class="row" style="justify-content: center;">
                                                    <div class="col-sm-9" style="padding-right: 0px !important;    margin-left: -35px;">
                                                        <label style="display: flex;justify-content: center;">
                                                            <input type="text" class="form-control" formControlName="email" [class.is-invalid] = "validateInitialRegistration && f2.email.errors" placeholder="Email" readonly style="width: 65%;">
                                                            <span class="checkout-label" style="background-color: white;padding-left: 5px;padding-right: 5px;">MemberId*</span>
                                                        </label> 
                                                    </div>
                                                    <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px; position: absolute;right: 8%;    margin-left: -35px;">
                                                        <label class="mobile-options" style="display: flex;justify-content: center;">
                                                            <a class="login-link-color" style="font-weight: 500; cursor: pointer;margin-left:25px;background-color: #dfcdcda6;width: 65%;margin-left: 17%;;" (click)="changeForm('initialstate')">Change?</a>
                                                        </label>                                     
                                                    </div> 
                                                </div>                 
                                            </div>
                                        </div>
                                        <div class="form-group mob-second" style="margin-top: 10px; margin-bottom: 10px !important;" *ngIf="otpType=='Email' || otpType=='Mobile'">
                                            <div class="row" style="justify-content: center;">
                                                <div class="col-sm-9" style="padding-right: 0px !important;    margin-left: -65px;">
                                                    <label>
                                                        <div style="display: flex;justify-content: center;">
                                                        <input type="text" class="form-control" formControlName="otp" [class.is-invalid] = "validateInitialRegistration && f2.otp.errors" placeholder="OTP" style="width: 73%;">
                                                        <span class="checkout-label left_cle_event" style="color:red ;padding-left: 5px;padding-right: 5px;left: 15% !important;">OTP*</span>
                                                        </div>
                                                        <div class="invalid-feedback" *ngIf="f2.otp.errors">
                                                            <div *ngIf="f2.otp.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter OTP.</div>
                                                        </div> 
                                                    </label>
                                                </div>
                                                <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 26px; position: absolute;right: 16%;margin-left: -65px;">
                                                    <label class="mobile-options">
                                                        <a class="login-link-color" style="font-weight: 500; cursor: pointer;color: white !important;width: 65%;" (click)="resendCode('otplogin2')">Resend?</a>
                                                    </label>                                     
                                                </div>
                                            </div>
                                            <small *ngIf="otpType=='Email'" class="help-text" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter the OTP that has been sent to your Email</small>
                                            <small *ngIf="otpType=='Mobile'" class="help-text" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter the OTP that has been sent to your Mobile</small>

                                        </div>
                                        <div class="form-group" *ngIf="otpType=='Email' || otpType=='Mobile'">
                                            <div class="row" style="justify-content: center;">
                                                <div class="col-sm-10" style="padding-right: 0px !important;    margin-left: -65px;">
                                                    <label>
                                                        <div style="display: flex;justify-content: center;">
                                                        <input [type]="showregistrationPswd ? 'text' : 'password'" class="form-control" formControlName="password" [class.is-invalid] = "validateInitialRegistration && f2.password.errors" placeholder="Set Password" style="width: 65%;">
                                                        <span class="checkout-label left_cle_event" style="color: red;padding-left: 5px;padding-right: 5px;left: 19% !important;">Set Password*</span>
                                                        </div>
                                                        <div class="invalid-feedback" *ngIf="f2.password.errors">
                                                            <div *ngIf="f1.password.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter the password</div>
                                                            <div *ngIf="f2.password.errors.maxlength && !f2.password.errors.minlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Password should not be more than 20 characters</div>
                                                            <div *ngIf="f2.password.errors.minlength && !f2.password.errors.maxlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Password should not be less than 6 characters</div>
                                                            <div *ngIf="f2.password.errors.incorrect" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please set the password for your account</div>
                                                        </div>
                                                    </label> 
                                                </div>
                                                <div class="col-sm-2 fcolor2" style="padding-left: 0px !important; margin-top: 31px; left: 57% !important; bottom: 21% !important;">
                                                    <label class="mobile-options">
                                                        <i (click)="showRegistrationPassword()" class="mob-eye lin-eye fcolor" [class]="showregistrationPswd ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                                                    </label>                                   
                                                </div> 
                                            </div>                 
                                        </div>
                                    </form>
                                    <!-- <div *ngIf="config.title ==='Babys Pride'">
                                    <form class="checkout-forms" [formGroup]= "individualRegistration">
                                        <div class="form-group mob-second special-form name-form">
                                            <label>
                                                <input type="text" class="form-control" formControlName="name" [class.is-invalid] = "validateIndividualRegistration && f3.name.errors" placeholder="Name">
                                                <span class="checkout-label">Name*</span>
                                                <div class="invalid-feedback" *ngIf="f3.name.errors">
                                                    <div *ngIf="f3.name.errors.required">Please enter Name.</div>
                                                    <div *ngIf="f3.name.errors.pattern">Enter a valid Name</div>
                                                    <div *ngIf="f3.name.errors.maxlength">Name should not have more than 80 characters</div>
                                                    <div *ngIf="f3.name.errors.minlength">Name should not have less than 2 characters</div>
                                                </div> 
                                            </label>
                                        </div>
                                        <div class="form-group" *ngIf="f2.email.value === '' || !f2.email.value">
                                            <label>
                                                <input type="text" class="form-control" formControlName="email" [class.is-invalid] = "validateIndividualRegistration && f3.email.errors"  placeholder="email" [value]="f2.email.value">
                                                <span class="checkout-label">Email*</span>
                                                <div class="invalid-feedback" *ngIf="f3.email.errors">
                                                    <div *ngIf="f3.email.errors.required">Please enter email.</div>
                                                    <div *ngIf="f3.email.errors.pattern">Enter a valid email.</div>
                                                </div> 
                                            </label>
                                        </div>
                                    </form>
                                </div> -->
                                    <!-- <div *ngIf="enableBusinessUser">
                                        <div style="margin-top: 25px;" *ngIf="config.title!='Karupattiraja'">
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input" value="individual" (change)="getUserType($event.target.value)" [checked]="customerType === 'individual'">
                                                <label class="custom-control-label custom-radio-text" style="border-bottom: none;" for="customRadioInline1">Individual User</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline custom-radios new_log">
                                                <input type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input" value="business" (change)="getUserType($event.target.value)">
                                                <label class="custom-control-label custom-radio-text" style="border-bottom: none;" for="customRadioInline2">Business User</label>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div style="display: flex;justify-content: center;">
                                    <button *ngIf="!is_mandatory.individual && customerType === 'individual'" class="btn btn-block btn-primary btn-primary1 continue_btn_login" style="width: 39% !important;margin-left: -6%;" (click)="signinWithOtp()">SIGN IN</button>
                                    <button *ngIf="!is_mandatory.business && customerType === 'business'" class="btn btn-block btn-primary btn-primary1 continue_btn_login" style="width: 39% !important;margin-left: -6%;" (click)="signinWithOtp()">SIGN IN</button>
                                </div>
                                    <!-- <div *ngIf="is_mandatory.individual && customerType === 'individual'">
                                        <form class="checkout-forms" [formGroup]= "individualRegistration">
                                            <div class="form-group mob-second special-form name-form">
                                                <label>
                                                    <input type="text" class="form-control" formControlName="name" [class.is-invalid] = "validateIndividualRegistration && f3.name.errors" placeholder="Name">
                                                    <span class="checkout-label">Name*</span>
                                                    <div class="invalid-feedback" *ngIf="f3.name.errors">
                                                        <div *ngIf="f3.name.errors.required">Please enter Name.</div>
                                                        <div *ngIf="f3.name.errors.pattern">Enter a valid Name</div>
                                                        <div *ngIf="f3.name.errors.maxlength">Name should not have more than 80 characters</div>
                                                        <div *ngIf="f3.name.errors.minlength">Name should not have less than 2 characters</div>
                                                    </div> 
                                                </label>
                                            </div>
                                            <div class="form-group" *ngIf="f2.email.value === '' || !f2.email.value">
                                                <label>
                                                    <input type="text" class="form-control" formControlName="email" [class.is-invalid] = "validateIndividualRegistration && f3.email.errors"  placeholder="email" [value]="f2.email.value">
                                                    <span class="checkout-label">Email*</span>
                                                    <div class="invalid-feedback" *ngIf="f3.email.errors">
                                                        <div *ngIf="f3.email.errors.required">Please enter email.</div>
                                                        <div *ngIf="f3.email.errors.pattern">Enter a valid email.</div>
                                                    </div> 
                                                </label>
                                            </div>
                                        </form>
                                        <div *ngIf="registrationOfferDetails.inidividual && registrationOfferDetails.available">
                                            <div class="row" style="margin-top: 30px;">
                                                <div class="col-md-8">
                                                    <p>We are offering free sample to first 1000 users, Want one?</p>
                                                </div>
                                                <div class="col-md-1">
                                                    <input type="checkbox" id="availOffer" name="availOffer" class="text-primary" style="width: 15px !important;height: 15px !important;" (change)="availOffer()" [checked]="offerAvailed">
                                                </div>
                                            </div>
                                            <div *ngIf="selectedAddress" class="row" style="padding-left: 15px;">
                                                <p class="text-primary" style="padding-bottom: 5px; padding-top: 15px;"><i class="fa fa-truck" aria-hidden="true"></i> Free Samples will be delivered to: <a class="text-primary" style="cursor: pointer;color: #777;" (click)="editCustomerAddress()">(Change)</a></p>
                                                <p style="font-size: 13px;">{{selectedAddress.entire_location}}</p>
                                            </div>
                                        </div>
                                        <button class="btn btn-block btn-primary btn-primary1" (click)="individualUserRegistration()" [disabled]="signup1"><P *ngIf="signup1" class="spinner-border spinner-border-sm"></P> SIGN UP</button>
                                    </div> -->
                                    <div *ngIf="is_mandatory.business && customerType === 'business'">
                                        <form class="checkout-forms" [formGroup]= "businessRegistration">
                                            <div class="form-group">
                                                <label>
                                                    <input type="text" class="form-control" formControlName="name" [class.is-invalid] = "validateBusinessRegistration && f4.name.errors" placeholder="Name">
                                                    <span class="checkout-label">Name*</span>
                                                    <div class="invalid-feedback" *ngIf="f4.name.errors">
                                                        <div *ngIf="f4.name.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter Name.</div>
                                                        <div *ngIf="f4.name.errors.pattern" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Enter a valid Name</div>
                                                        <div *ngIf="f4.name.errors.maxlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Name should not have more than 80 characters</div>
                                                        <div *ngIf="f4.name.errors.minlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Name should not have less than 2 characters</div>
                                                    </div> 
                                                </label>
                                            </div>
                                            <div class="form-group" *ngIf="f2.email.value === '' || !f2.email.value">
                                                <label>
                                                    <input type="text" class="form-control" formControlName="email" [class.is-invalid] = "validateBusinessRegistration && f4.email.errors"  placeholder="email" [value]="f4.email.value">
                                                    <span class="checkout-label">Email*</span>
                                                    <div class="invalid-feedback" *ngIf="f4.email.errors">
                                                        <div *ngIf="f4.email.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter email.</div>
                                                        <div *ngIf="f4.email.errors.pattern" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Enter a valid email.</div>
                                                    </div> 
                                                </label>
                                            </div>
                                            <div class="form-group">
                                                <label>
                                                    <input type="text" class="form-control" formControlName="company" [class.is-invalid] = "validateBusinessRegistration && f4.company.errors" placeholder="Company Name">
                                                    <span class="checkout-label">Company Name*</span>
                                                    <div class="invalid-feedback" *ngIf="f4.company.errors">
                                                
                                                            <div *ngIf="f4.company.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter Company Name.</div>
                                                            <div *ngIf="f4.company.errors.maxlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Company Name should not be more than 80 characters</div>
                                                            <div *ngIf="f4.company.errors.minlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Company Name should not be less than 5 characters</div>
                                    
                                                    </div> 
                                                </label>                    
                                            </div>
                                            <div class="form-group">
                                                <label>
                                                    <textarea class="form-control" formControlName="company_address" [class.is-invalid] = "validateBusinessRegistration && f4.company_address.errors" placeholder="Company Address"></textarea>
                                                    <span class="checkout-label" *ngIf="config.title!=='Karupattiraja'">Company Address*</span>
                                                    <span class="checkout-label" *ngIf="config.title==='Karupattiraja'">Company Full Address*</span>
                                                    <div class="invalid-feedback" *ngIf="f4.company_address.errors">
                                                        <div *ngIf="f4.company_address.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter Company Address.</div>
                                                        <div *ngIf="f4.company_address.errors.maxlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Company Address should not be more than 300 characters</div>
                                                        <div *ngIf="f4.company_address.errors.minlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Company Address should not be less than 5 characters</div>
                                                    </div> 
                                                </label>
                                            </div>
                                            <!-- <div class="form-group" *ngIf="config.title=='Karupattiraja'">
                                                <label>
                                                    <input type="text" class="form-control" formControlName="email" [class.is-invalid] = "validateBusinessRegistration && f4.email.errors"  placeholder="email" [value]="f2.email.value">
                                                    <span class="checkout-label">Email*</span>
                                                    <div class="invalid-feedback" *ngIf="f3.email.errors">
                                                        <div *ngIf="f4.email.errors.required">Please enter email.</div>
                                                        <div *ngIf="f4.email.errors.pattern">Enter a valid email.</div>
                                                    </div>
                                                </label>
                                            </div> -->
                                            <div class="form-group" *ngIf="config.title=='Karupattiraja'">
                                                <label>
                                                    <input type="text" class="form-control" formControlName="city" [class.is-invalid] = "validateBusinessRegistration && f4.city.errors" placeholder="City">
                                                    <span class="checkout-label">City*</span>
                                                    <div class="invalid-feedback" *ngIf="f4.city.errors">
                                                        <div *ngIf="f4.city.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter City.</div>
                                                                 </div> 
                                                </label>
                                            </div>
                                            <div class="form-group" *ngIf="config.title=='Karupattiraja'">
                                            <label>
                                                <input type="text" class="form-control" formControlName="state" [class.is-invalid] = "validateBusinessRegistration && f4.state.errors" placeholder="State">
                                                <span class="checkout-label">State*</span>
                                                <div class="invalid-feedback" *ngIf="f4.state.errors">
                                                    <div *ngIf="f4.state.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter State.</div>
                                                             </div> 
                                            </label>
                                        </div>
                                        <!-- <div class="form-group" *ngIf="config.title=='Karupattiraja'">
                                        <label>
                                            <input type="text" class="form-control" formControlName="country" [class.is-invalid] = "validateBusinessRegistration && f4.country.errors" placeholder="Country">
                                            <span class="checkout-label">Country*</span>
                                            <div class="invalid-feedback" *ngIf="f4.country.errors">
                                                <div *ngIf="f4.country.errors.required">Please enter Country.</div>
                                                         </div> 
                                        </label>
                                    </div> -->
                                    <div class="form-group" *ngIf="config.title=='Karupattiraja'">
                                        <label>
                                            <input type="number" class="form-control" formControlName="zipcode" [class.is-invalid] = "validateBusinessRegistration && f4.zipcode.errors" placeholder="Zipcode" (change)="getlat_long($event)">
                                            <span class="checkout-label">Pincode*</span>
                                            <div class="invalid-feedback" *ngIf="f4.zipcode.errors">
                                                <div *ngIf="f4.zipcode.errors.required">Please enter Zipcode.</div>
                                                <div *ngIf="f4.zipcode.errors.max">Enter a valid Zip Code.</div>
                                                        <div *ngIf="f4.zipcode.errors.min">Enter a valid Zip Code.</div>
                                                         </div> 
                                        </label>
                                    </div>
                                            <div class="form-group">
                                                <label>
                                                    <input type="text" class="form-control" formControlName="gst_no" [class.is-invalid] = "validateBusinessRegistration && f4.gst_no.errors" placeholder="GST Number">
                                                    <span *ngIf="config.title!='Karupattiraja' && config.title!='BestGifts'"class="checkout-label">GST Number*</span>
                                                    <span *ngIf="config.title=='Karupattiraja'|| config.title=='BestGifts'"class="checkout-label">GST Number</span>
                                                   
                                                    <div class="invalid-feedback"  *ngIf="f4.gst_no.errors&&config.title!='Karupattiraja'">
                                                        <div *ngIf="f4.gst_no.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter GST Number.</div>
                                                        <div *ngIf="f4.gst_no.errors.maxlength && !f4.gst_no.errors.minlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Enter a valid GST Number.</div>
                                                        <div *ngIf="f4.gst_no.errors.minlength && !f4.gst_no.errors.maxlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Enter a valid GST Number.</div>
                                                    </div> 
                                                </label>
                                            </div>
                                            <div class="form-group hide_pan" >
                                                <label>
                                                    <input type="text" class="form-control" formControlName="pan_no" [class.is-invalid] = "validateBusinessRegistration && f4.pan_no.errors" placeholder="PAN Number">
                                                    <span *ngIf="config.title=='BestGifts'"  class="checkout-label">PAN Number</span>
                                                    <span  *ngIf="config.title!='BestGifts'"   class="checkout-label">PAN Number*</span>
                                                    <div class="invalid-feedback" *ngIf="f4.pan_no.errors">
                                                        <div *ngIf="f4.pan_no.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter PAN No.</div>
                                                        <div *ngIf="f4.pan_no.errors.maxlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Enter a valid PAN Number.</div>
                                                        <div *ngIf="f4.pan_no.errors.minlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Enter a valid PAN Number.</div>
                                                    </div> 
                                                </label>
                                            </div>
                                        </form>
                                        <div *ngIf="registrationOfferDetails.business && registrationOfferDetails.available">
                                            <div class="row" style="margin-top: 30px;">
                                                <div class="col-md-8">
                                                    <p>We are offering free sample to first 1000 users, Want one?</p>
                                                </div>
                                                <div class="col-md-1">
                                                    <input type="checkbox" id="availOffer" name="availOffer" class="text-primary" style="width: 15px !important;height: 15px !important;" (change)="availOffer()" [checked]="offerAvailed">
                                                </div>
                                            </div>
                                            <div *ngIf="selectedAddress" class="row" style="padding-left: 15px;">
                                                <p class="text-primary" style="padding-bottom: 5px; padding-top: 15px;"><i class="fa fa-truck" aria-hidden="true"></i> Free Samples will be delivered to: <a class="text-primary" style="cursor: pointer;color: #777;" (click)="editCustomerAddress()">(Change)</a></p>
                                                <p style="font-size: 13px;">{{selectedAddress.entire_location}}</p>
                                            </div>
                                        </div>
                                        <button class="btn btn-block btn-primary btn-primary1" (click)="businessUserRegistration()" [disabled]="signup2"><P *ngIf="signup2" class="spinner-border spinner-border-sm"></P> SIGN UP</button>
                                    </div>
                                </div>
                                <!-- EMAIL UPDATE FORM1 -->
                                <div *ngIf="screenType === 'updateEmailForm1'">
                                    <form class="checkout-forms" [formGroup]= "updateEmailForm1">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-sm-9" style="padding-right: 0px !important;">
                                                    <label>
                                                        <input type="text" class="form-control" formControlName="email" [class.is-invalid] = "validateUpdateEmailForm1 && f8.email.errors" placeholder="Email" readonly>
                                                        <span class="checkout-label">Email*</span>
                                                    </label> 
                                                </div>
                                                <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                                    <label class="mobile-options">
                                                        <a class="login-link-color" style="font-weight: 500; cursor: pointer;margin-left:25px;background-color: #ffffff00;width: 65%;margin-left: 17%;;" (click)="changeForm('initialstate')">Change?</a>
                                                    </label>                                    
                                                </div> 
                                            </div>                 
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-sm-9" style="padding-right: 0px !important;">
                                                    <label>
                                                        <input type="text" class="form-control" formControlName="mobile" [class.is-invalid] = "validateUpdateEmailForm1 && f8.mobile.errors" placeholder="Mobile No" readonly>
                                                        <span class="checkout-label">Mobile No*</span>
                                                    </label> 
                                                </div>
                                                <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                                    <label class="mobile-options">
                                                        <a class="login-link-color" style="font-weight: 500; cursor: pointer;margin-left:25px;background-color: #ffffff00;width: 65%;margin-left: 17%;;" (click)="changeForm('initialstate')">Change?</a>
                                                    </label>                                     
                                                </div> 
                                            </div>                 
                                        </div>
                                        <div class="form-group mob-second">
                                            <div class="row">
                                                <div class="col-sm-9" style="padding-right: 0px !important;">
                                                    <label>
                                                        <input type="text" class="form-control" formControlName="otp" [class.is-invalid] = "validateUpdateEmailForm1 && f8.otp.errors" placeholder="OTP">
                                                        <span class="checkout-label">OTP*</span>
                                                        <div class="invalid-feedback" *ngIf="f8.otp.errors">
                                                            <div *ngIf="f8.otp.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter OTP.</div>
                                                        </div> 
                                                    </label>
                                                </div>
                                                <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                                    <label class="mobile-options">
                                                        <a class="login-link-color" style="font-weight: 500; cursor: pointer;margin-left:25px;background-color: #ffffff00;width: 65%;margin-left: 17%;;" (click)="resendCode('otplogin2')">Resend?</a>
                                                    </label>                                     
                                                </div>
                                            </div>
                                            <small class="help-text" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter the OTP that has been sent to your Mobile Number</small>
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-sm-10" style="padding-right: 0px !important;">
                                                    <label>
                                                        <input [type]="showUpdateEmailForm1 ? 'text' : 'password'" class="form-control" formControlName="password" [class.is-invalid] = "validateUpdateEmailForm1 && f8.password.errors" placeholder="Set Password">
                                                        <span class="checkout-label">Set Password*</span>
                                                        <div class="invalid-feedback" *ngIf="f8.password.errors">
                                                            <div *ngIf="f8.password.errors.maxlength && !f8.password.errors.minlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Password should not be more than 20 characters</div>
                                                            <div *ngIf="f8.password.errors.minlength && !f8.password.errors.maxlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Password should not be less than 6 characters</div>
                                                        </div>
                                                    </label> 
                                                </div>
                                                <div class="col-sm-2 fcolor1" style="padding-left: 0px !important; margin-top: 31px;">
                                                    <label>
                                                        <i (click)="showUpdateEmailForm1M()" class="mob-eye fcolor" [class]="showUpdateEmailForm1 ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                                                    </label>                                   
                                                </div> 
                                            </div>                 
                                        </div>
                                    </form>
                                    <button class="btn btn-block btn-primary btn-primary1" (click)="signinupdateEmailForm1()">SIGN IN</button>
                                </div> 
                                <!-- EMAIL UPDATE FORM3 -->
                                <div *ngIf="screenType === 'updateEmailForm3'">
                                    <form class="checkout-forms" [formGroup]= "updateEmailForm3">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-sm-9" style="padding-right: 0px !important;">
                                                    <label>
                                                        <input type="text" class="form-control" formControlName="email" [class.is-invalid] = "validateUpdateEmailForm3 && f9.email.errors" placeholder="Email" readonly>
                                                        <span class="checkout-label">Email*</span>
                                                    </label> 
                                                </div>
                                                <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                                    <label class="mobile-options">
                                                        <a class="login-link-color" style="font-weight: 500; cursor: pointer;margin-left:25px;background-color: #ffffff00;width: 65%;margin-left: 17%;;" (click)="changeForm('initialstate')">Change?</a>
                                                    </label>                                     
                                                </div> 
                                            </div>                 
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-sm-9" style="padding-right: 0px !important;">
                                                    <label>
                                                        <input type="text" class="form-control" formControlName="mobile" [class.is-invalid] = "validateUpdateEmailForm3 && f9.mobile.errors" placeholder="Mobile No" readonly>
                                                        <span class="checkout-label">Mobile No*</span>
                                                    </label> 
                                                </div>
                                                <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                                    <label class="mobile-options">
                                                        <a class="login-link-color" style="font-weight: 500; cursor: pointer;margin-left:25px;background-color: #ffffff00;width: 65%;margin-left: 17%;;" (click)="changeForm('initialstate')">Change?</a>
                                                    </label>                                    
                                                </div> 
                                            </div>                 
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-sm-10" style="padding-right: 0px !important;">
                                                    <label>
                                                        <input [type]="showUpdateEmailForm3 ? 'text' : 'password'" class="form-control" formControlName="password" [class.is-invalid] = "validateUpdateEmailForm3 && f9.password.errors" placeholder="Password">
                                                        <span class="checkout-label">Password*</span>
                                                        <div class="invalid-feedback" *ngIf="f9.password.errors">
                                                            <div *ngIf="f9.password.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter the password</div>
                                                        </div>
                                                    </label> 
                                                </div>
                                                <div class="col-sm-2 fcolor1" style="padding-left: 0px !important; margin-top: 31px;">
                                                    <label>
                                                        <i (click)="showUpdateEmailForm3M()" class="mob-eye fcolor" [class]="showUpdateEmailForm3 ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                                                    </label>                                   
                                                </div> 
                                            </div>                 
                                        </div>
                                    </form>
                                    <button class="btn btn-block btn-primary btn-primary1" (click)="signinupdateEmailForm3()">SIGN IN</button>
                                    <!-- <div class="text-center" style="padding: 5px 0px;">
                                        <p>OR</p>
                                    </div>
                                    <button class="btn btn-block btn-primary btn-secondary1" (click)="requesttogetOTPpswd()">REQUEST OTP</button> -->
                                </div> 
                                <!-- EMAIL UPDATE FORM2 -->
                                <div *ngIf="screenType === 'updateEmailForm2'">
                                    <form class="checkout-forms" [formGroup]= "updateEmailForm2">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-sm-9" style="padding-right: 0px !important;">
                                                    <label>
                                                        <input type="text" class="form-control" formControlName="email" [class.is-invalid] = "validateUpdateEmailForm2 && f10.email.errors" placeholder="Email" readonly>
                                                        <span class="checkout-label">Email*</span>
                                                    </label> 
                                                </div>
                                                <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                                    <label class="mobile-options">
                                                        <a class="login-link-color" style="font-weight: 500; cursor: pointer;margin-left:25px;background-color: #ffffff00;width: 65%;margin-left: 17%;;" (click)="changeForm('initialstate')">Change?</a>
                                                    </label>                                     
                                                </div> 
                                            </div>                 
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-sm-9" style="padding-right: 0px !important;">
                                                    <label>
                                                        <input type="text" class="form-control" formControlName="mobile" [class.is-invalid] = "validateUpdateEmailForm2 && f10.mobile.errors" placeholder="Mobile No" readonly>
                                                        <span class="checkout-label">Mobile No*</span>
                                                    </label> 
                                                </div>
                                                <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                                    <label class="mobile-options">
                                                        <a class="login-link-color" style="font-weight: 500; cursor: pointer;margin-left:25px;background-color: #ffffff00;width: 65%;margin-left: 17%;;" (click)="changeForm('initialstate')">Change?</a>
                                                    </label>                                     
                                                </div> 
                                            </div>                 
                                        </div>
                                        <div class="form-group mob-second">
                                            <div class="row">
                                                <div class="col-sm-9" style="padding-right: 0px !important;">
                                                    <label>
                                                        <input type="text" class="form-control" formControlName="otp" [class.is-invalid] = "validateUpdateEmailForm2 && f10.otp.errors" placeholder="OTP">
                                                        <span class="checkout-label">OTP*</span>
                                                        <div class="invalid-feedback" *ngIf="f10.otp.errors">
                                                            <div *ngIf="f10.otp.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter OTP.</div>
                                                        </div> 
                                                    </label>
                                                </div>
                                                <div class="col-sm-3" style="padding-left: 0px !important; margin-top: 31px;">
                                                    <label class="mobile-options">
                                                        <a class="login-link-color" style="font-weight: 500; cursor: pointer;margin-left:25px;background-color: #ffffff00;width: 65%;margin-left: 17%;;" (click)="resendCode('otplogin2')">Resend?</a>
                                                    </label>                                     
                                                </div>
                                            </div>
                                            <small class="help-text" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter the OTP that has been sent to your Mobile Number</small>
                                        </div>
                                    </form>
                                    <button class="btn btn-block btn-primary btn-primary1" (click)="signinupdateEmailForm2()">SIGN IN</button>
                                </div>                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<button id="launch-address" style="display: none;" type="button" class="btn btn-primary" data-toggle="modal" data-target="#statusModel">
    Launch demo modal
  </button>
  
<div class="modal fade" id="statusModel" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content status-model-container text-center">
        <div class="modal-body" style="border: none !important;">
            <h4 class="text-primary">Add Shipping Address to deliver Free Sample</h4>
            <div style="padding: 15px 15px;">
                <form class="checkout-forms" [formGroup]="shippingForm">
                    <div class="row">
                        <div class="col-md-4">
                            <button class="btn btn-block btn-primary btn-primary1" (click)="getCurrentLocation()" style="margin-top: 0px !important; margin-bottom: 5px !important;"><i class="fa fa-map-marker"></i> USE MY LOCATION</button>
                        </div>
                        <small *ngIf="location_bloked" style="font-size: 12px; color: #c21212; margin-top: 5px !important;">Oops! It seems like you have blocked accesing your location. <br><a style="font-size: 12px; color: blue !important;" href="https://support.google.com/chrome/answer/142065?hl=en" target="_blank">Click here to get instructions to reactivate the location sharing.</a></small>
                        <small *ngIf="!is_deliverable" style="font-size: 14px; color: #c21212; margin-top: 10px !important; margin-bottom: 10px;">Sorry, We can't deliver to your Location</small>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>
                                    <input ngx-google-places-autocomplete style="overflow: visible;" [options]='optionss' id="enterlocation" type="text" class="form-control" formControlName="location" [class.is-invalid]="validateShippingForm && f11.location.errors" placeholder="Enter Location" #placesRef="ngx-places" (onAddressChange)="changeAddress($event)">
                                    <span class="checkout-label">Enter Location*</span>
                                    <div class="invalid-feedback" *ngIf="f11.location.errors">
                                        <div *ngIf="f11.location.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please select your location from Maps.</div>
                                        <div *ngIf="f11.location.errors.incorrect" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;"></div>
                                        <div *ngIf="f11.location.errors.incorrect1" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter a valid Address.</div>
                                    </div>
                                </label>
                            </div>
                            <div class="form-group">
                                <label>
                                    <input type="text" class="form-control" formControlName="flat_no" [class.is-invalid]="validateShippingForm && f11.flat_no.errors" placeholder="Flat No/Building Name/Street Name">
                                    <span class="checkout-label">Flat No/Building Name/Street Name*</span>
                                    <div class="invalid-feedback" *ngIf="f11.flat_no.errors">
                                        <div *ngIf="f11.flat_no.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter the Flat No/Building Name/Street Name.</div>
                                    </div>
                                </label>
                            </div>
                            <div class="form-group">
                                <label>
                                    <input type="text" class="form-control" formControlName="landmark" [class.is-invalid]="validateShippingForm && f11.landmark.errors" placeholder="Flat No/Building Name/Street Name">
                                    <span class="checkout-label">Landmark</span>
                                    <div class="invalid-feedback" *ngIf="f11.landmark.errors">
                                        <!-- <div *ngIf="f.landmark.errors.required">Please select your location from Maps.</div> -->
                                    </div>
                                </label>
                            </div>
                            <div class="form-group">
                                <label>
                                    <input type="text" class="form-control" formControlName="city" [class.is-invalid]="validateShippingForm && f11.city.errors" placeholder="City" readonly>
                                    <span class="checkout-label">City</span>
                                    <div class="invalid-feedback" *ngIf="f11.city.errors">
                                        <!-- <div *ngIf="f.city.errors.required">Please enter the city.</div> -->
                                    </div>
                                </label>
                            </div>
                            <div class="form-group">
                                <label>
                                    <input type="text" class="form-control" formControlName="mobile" [class.is-invalid]="validateShippingForm && f11.mobile.errors" placeholder="Mobile Number">
                                    <span class="checkout-label">Mobile Number*</span>
                                    <div class="invalid-feedback" *ngIf="f11.mobile.errors">
                                        <div *ngIf="f11.mobile.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter Mobile Number.</div>
                                        <div *ngIf="f11.mobile.errors.pattern && (!f11.mobile.errors.maxlength || !f11.mobile.errors.minlength)" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Enter a valid Mobile Number</div>
                                        <div *ngIf="!f11.mobile.errors.pattern && f11.mobile.errors.maxlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Enter a valid Mobile Number</div>
                                        <div *ngIf="!f11.mobile.errors.pattern && f11.mobile.errors.minlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Enter a valid Mobile Number</div>
                                        <div *ngIf="f11.mobile.errors.incorrect" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Enter a valid Mobile Number</div>
                                    </div>
                                </label>
                            </div>
                            <div class="form-group">
                                <label>
                                    <input type="text" class="form-control" formControlName="email" [class.is-invalid]="validateShippingForm && f11.email.errors" placeholder="Email">
                                    <span class="checkout-label">Email</span>
                                    <div class="invalid-feedback" *ngIf="f11.email.errors">
                                        <div *ngIf="f11.email.errors.pattern" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Enter a valid Email</div>
                                        <div *ngIf="f11.email.errors.maxlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Email should not be more than 50 Charchters</div>
                                    </div>
                                </label>
                            </div>
                            <div class="form-group">
                                <label>
                                    <input type="text" class="form-control" formControlName="full_name" [class.is-invalid]="validateShippingForm && f11.full_name.errors" placeholder="Full Name">
                                    <span class="checkout-label">Full Name*</span>
                                    <div class="invalid-feedback" *ngIf="f11.full_name.errors">
                                        <div *ngIf="f11.full_name.errors.required" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Please enter Name.</div>
                                        <div *ngIf="f11.full_name.errors.pattern" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Enter a valid Name</div>
                                        <div *ngIf="f11.full_name.errors.maxlength" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Name should not be more than 50 Charchters</div>
                                        <div *ngIf="f11.full_name.errors.incorrect" style="background-color: #ffffff00;width: 65%;margin-left: 17%;;color: #da0000;">Enter a valid Name</div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <agm-map [latitude]="latitude" [longitude]="longitude"  [streetViewControl]=false [zoom]="zoom" (mapClick)="selectLocation($event)">
                                <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                                <button>Current Location</button>
                            </agm-map>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-md-4">
                        <button class="btn btn-block btn-primary btn-primary1" (click)="saveAddress()" [disabled]="saveaddSpinner"><P *ngIf="saveaddSpinner" class="spinner-border spinner-border-sm"></P> SAVE</button>
                    </div>
                    <div class="col-md-4">
                        <button class="btn btn-block btn-primary btn-secondary1" style="margin-top: 20px;" (click)="cancelSave()">CANCEL</button>
                    </div>
                </div>
            </div>
        </div>
        <button style="display: none;" type="button" id="cancelBtn1" class="btn btn-light btn-md text-primary shadow-sm" data-dismiss="modal">Cancel</button>          
      </div>
    </div>
  </div>

  <button id="launch-surprise" style="display: none;" type="button" class="btn btn-primary" data-toggle="modal" data-target="#statusModel1">
    Launch Surprise
  </button>
  <div class="modal fade" id="statusModel1" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content status-model-container text-center surprise-continer">
            <div class="firework-1"></div>
            <div class="firework-2"></div>
            <div class="firework-3"></div>
            <div class="firework-4"></div>
            <div class="firework-5"></div>
            <div class="firework-6"></div>
            <div class="firework-7"></div>
            <div class="firework-8"></div>
            <div class="firework-9"></div>
            <div class="firework-10"></div>
            <div class="firework-11"></div>
            <div class="firework-12"></div>
            <div class="firework-13"></div>
            <div class="firework-14"></div>
            <div class="firework-15"></div>
            <div class="title">Congratulations!!! 🎉</div>
            <div class="surprise-text text-center">
                On being in the first 1000 users. 
                your order confirmation number is {{offerOrderId}}. sign in to track status of delivery.
            </div>
            <div>
                <button style="display: none;" type="button" id="cancelBtn2" data-dismiss="modal">Cancel</button> 
                <button class="btn surprise-btn" (click)="closeModel1()">OK</button>
            </div>
        </div>
    </div>
  </div>

  
